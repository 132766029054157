import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetProduct, UpdateProduct } from '@app/api/Product.api';
import { ProductModel } from '@app/domain/Product.model';
import * as S from './SingleProductPage.style';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { Categories } from '@app/api/Category.api';
import { notificationController } from '@app/controllers/notificationController';
import { CategoryModel } from '@app/domain/CategoryModel';
import { BrandData } from '@app/interfaces/brand.interface';
import { Brands } from '@app/api/Brand.api';
import ProductWizardForm from './ProductWizardForm';
import {
  Attribute,
  BrandShowModel,
  CategoryOptionsModel,
  CategoryShowModel,
  Prices,
  ProductFormData,
  Tags,
  VideoGallery,
} from '@app/constants/productDetails';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export const SingleProduct: React.FC = () => {
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string }>();
  const [product, setProduct] = useState<ProductModel | null>(null);
  const [formData, setFormData] = useState<ProductFormData>({
    title: '',
    description: '',
    shortDescription: '',
    sku: '',
    categories: [],
    tags: [],
    attributes: [],
    prices: [],
  });
  const [categoryOptions, setCategoryOptions] = useState<CategoryOptionsModel[]>([]);
  const [isEditing, setIsEditing] = useState(true);

  const mapToTreeData = (categories: CategoryModel[]): CategoryOptionsModel[] => {
    return categories.map((category) => ({
      label: category.title,
      value: category.id,
      key: category.id,
      children: category.categories && category.categories.length > 0 ? mapToTreeData(category.categories) : [],
    }));
  };

  const fetchCategories = useCallback(() => {
    Categories({ type: 'product', page: 1 })
      .then((response) => {
        if (response.success) {
          const treeData = mapToTreeData(response.data.allCategories);
          setCategoryOptions(treeData);
        } else {
          notificationController.error({ message: response.message });
        }
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  const [brands, setBrands] = useState<BrandData[]>([]);

  const getBrands = () => {
    setBrands([]);
    Brands()
      .then((result) => {
        if (result.success) {
          result.data.brands.map((brand, index) => {
            setBrands((brands) => [
              ...brands,
              {
                index: brand.id,
                title: brand.title,
              },
            ]);
          });
        }
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });
  };

  useEffect(() => {
    getBrands();
  }, []);

  const getProduct = () => {
    if (uuid) {
      GetProduct(uuid)
        .then((productData) => {
          setProduct(productData.data);
          // console.log(productData.data);
          setFormData({
            id: productData.data.id.toString(),
            title: productData.data.title,
            description: productData.data.description,
            shortDescription: productData.data.shortDescription,
            sku: productData.data.sku,
            status: productData.data.status,
            categories: productData.data.categories.map((cat) => ({
              label: cat.title,
              value: cat.id,
            })),
            tags: productData.data.tags.map((tag) => ({
              name: tag.name,
              id: tag.id,
            })),
            attributes: productData.data.attributes,
            brand: {
              label: productData.data.brand.title,
              value: productData.data.brand.id,
            },
            prices: productData.data.prices.map((price) => ({
              price: price.price,
              stock: price.stock,
              id: price.id,
            })),
          });
        })
        .catch((error) => console.error('Error fetching product:', error));
    }
  };

  useEffect(() => {
    getProduct();
  }, [uuid, isEditing]);

  const setProductAgain = () => {
    if (uuid) {
      GetProduct(uuid)
        .then((productData) => {
          setProduct(productData.data);
          console.log('done')
        })
        .catch((error) => console.error('Error fetching product:', error));
    }
  }

  function isBrandShowModel(obj: any): obj is BrandShowModel {
    return (
      obj &&
      typeof obj === 'object' &&
      'label' in obj &&
      'value' in obj &&
      typeof obj.label === 'string' &&
      typeof obj.value === 'number'
    );
  }

  const updateProduct = async (formData: ProductFormData) => {
    const data = new FormData();

    for (const [key, value] of Object.entries(formData)) {
      if (Array.isArray(value)) {
        if (key === 'prices') {
          (value as Prices[]).forEach((item: Prices, index: number) => {
            if (item.price) {
              data.append(`prices[${index}][price]`, item.price.toString());
            }
            if (item.stock !== undefined) {
              data.append(`prices[${index}][stock]`, item.stock.toString());
            }
            data.append(`prices[${index}][id]`, item.id.toString());
            data.append(`prices[${index}][min]`, '0');
            data.append(`prices[${index}][max]`, '0');
          });
        } else if (key === 'categories') {
          (value as CategoryShowModel[]).forEach((category, index) => {
            data.append(`categories[${index}][label]`, category.label);
            data.append(`categories[${index}][value]`, category.value.toString());
          });
        } else if (key === 'tags') {
          (value as Tags[]).forEach((tag, index) => {
            data.append(`tags[${index}][name]`, tag.name);
            data.append(`tags[${index}][id]`, tag.id.toString());
          });
        } else if(key === 'attributes') {
          (value as Attribute[]).forEach((attribute, index) => {
            data.append(`attributes[${index}][id]`, attribute.id?.toString() || '0');
            data.append(`attributes[${index}][title]`, attribute.title || '');
            data.append(`attributes[${index}][value]`, attribute.value || '');
            data.append(`attributes[${index}][valueId]`, attribute.valueId?.toString() || '0');
            attribute.items.forEach((_attribute, _index) => {
              data.append(`attributes[${index}][items][${_index}][id]`, _attribute.id?.toString() || '0');
              data.append(`attributes[${index}][items][${_index}][title]`, _attribute.title || '');
              data.append(`attributes[${index}][items][${_index}][value]`, _attribute.value || '');
              data.append(`attributes[${index}][items][${_index}][valueId]`, _attribute.valueId?.toString() || '0');
              _attribute.items.forEach((attr, i) => {
                data.append(`attributes[${index}][items][${_index}][items][${i}][id]`, attr.id?.toString() || '0');
                data.append(`attributes[${index}][items][${_index}][items][${i}][title]`, attr.title || '');
                data.append(`attributes[${index}][items][${_index}][items][${i}][value]`, attr.value || '');
                data.append(`attributes[${index}][items][${_index}][items][${i}][valueId]`, attr.valueId?.toString() || '0');
              })
            });
          });
        } else if (key === 'imageGallery') {
          (value as File[]).forEach((file: File, index: number) => {
            data.append(key, file);
          });
        } else if (key === 'fileGallery') {
          (value as VideoGallery[]).forEach((file: VideoGallery, index: number) => {
            data.append(`titleFileGallery[${index}]`, file.title);
            data.append(key, file.file as File);
          });
        } else if (key === 'videoGallery') {
          (value as VideoGallery[]).forEach((video: VideoGallery, index: number) => {
            data.append(`titleVideoGallery[${index}]`, video.title);
            data.append(key, video.file as File);
          });
        } else {
          (value as (string | File)[]).forEach((item) => data.append(key, item));
        }
      } else if (key === 'thumbnail' || key === 'imageGallery' || key === 'fileGallery') {
        console.log('key', key);
        data.append(key, value as string);
      } else if (isBrandShowModel(value)) {
        if (key === 'brand') {
          data.append(`brand[label]`, (value as BrandShowModel).label);
          data.append(`brand[value]`, (value as BrandShowModel).value.toString());
        }
      } else {
        if (value !== undefined && value !== null) {
          data.append(key, value.toString());
        }
      }
    }
    data.append('id', formData.id as string);


    UpdateProduct(product!.id, data).then((response) => {
      if (response.success) {
        notificationController.success({ message: 'Product updated successfully!' });
        setProduct({
          ...product,
          sku: '',
          title: '',
          shortDescription: '',
          thumbnail: '',
          prices: [],
          tags: [],
          attributes: [],
          categories: [],
          imageGalleries: [],
        } as ProductModel);
        getProduct();
        setIsEditing(false);
      } else {
        console.error('Error updating product:', response.message);
        notificationController.error({ message: `Error updating product: ${response.message}` });
      }
    });
  };

  if (!formData || !product) {
    return <S.Loading>Loading...</S.Loading>;
  }

  return (
    <S.ProductWrapper>
      <PageTitle>Products</PageTitle>
      {isEditing ? (
        <ProductWizardForm
          formData={formData}
          setFormData={setFormData}
          updateProduct={updateProduct}
          categoryOptions={categoryOptions}
          brands={brands}
          isUpdating={true}
          product={product}
          setProductAgain={setProductAgain}
        />
      ) : (
        <S.ProductDetails>
          {product.thumbnail && (
            <div>
              <strong>{t('products.thumbnail')}:</strong>
              <img src={product.thumbnail} alt="Product Thumbnail" width={200} />
            </div>
          )}

          {product.imageGalleries && product.imageGalleries.length > 0 && (
            <div>
              <strong>{t('products.imageGalleries')}:</strong>
              <div>
                {product.imageGalleries.map((image, index) => (
                  <img key={index} src={image.image} alt={`Product Image ${index + 1}`} width={100} />
                ))}
              </div>
            </div>
          )}
          <p>
            <strong>{t('products.title')}:</strong> {product.title}
          </p>
          <p>
            <strong>{t('products.description')}:</strong> {product.shortDescription}
          </p>
          <p>
            <strong>{t('products.sku')}:</strong> {product.sku}
          </p>
          <p>
            <strong>{t('products.status')}:</strong> {product.status}
          </p>
          <p>
            <strong>{t('products.categories')}:</strong> {product.categories.map((cat) => cat.title).join(', ')}
          </p>
          <p>
            <strong>{t('products.tags')}:</strong> {product.tags.map((tag) => tag.name).join(', ')}
          </p>
          <p>
            <strong>{t('products.brand')}:</strong> {product.brand.title}
          </p>
          <p>
            <strong>{t('products.prices')}:</strong>
          </p>
          <ul>
            {product.prices.map((price, index) => (
              <li key={index}>
                Price: ${price.price}, Stock: {price.stock}
              </li>
            ))}
          </ul>
          <BaseSpace>
            <BaseButton type="ghost" onClick={() => setIsEditing(true)}>
              {t('edit.product')}
            </BaseButton>
          </BaseSpace>
        </S.ProductDetails>
      )}
    </S.ProductWrapper>
  );
};
