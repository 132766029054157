import { httpApi } from '@app/api/http.api';
import { UserModal } from '@app/domain/User.model';
import { UserAddressModel } from '@app/domain/UserAddresssModel';

export interface UserSearchRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  status?: string;
}

export interface UserSearchResponse {
  success: boolean;
  message: string;
  code: number;
  data: UserModal[];
}

export interface UserAddressResponse {
  success: boolean;
  message: string;
  code: number;
  data: UserAddressModel[];
}

export const SearchUser = (userPayload: UserSearchRequest): Promise<UserSearchResponse> =>
  httpApi.post<UserSearchResponse>('users/search', userPayload).then(({ data }) => data);

export const UserAddress = (id: number): Promise<UserAddressResponse> =>
  httpApi.get<UserAddressResponse>('users/addresses/' + id).then(({ data }) => data);
