import { httpApi } from '@app/api/http.api';
import { PRODUCT_STATUS } from '@app/constants/enums/product.status';
import { Attribute } from '@app/constants/productDetails';
import { ProductModel } from '@app/domain/Product.model';

export interface ProductSearchRequest {
  title?: string;
  status?: string;
  brand?: string;
  sku?: string;
  isStock?: string;
}

export interface ProductSearchResponse {
  success: boolean;
  message: string;
  code: number;
  data: { products: ProductModel[]; totalCount: number };
}

export interface ProductResponse {
  success: boolean;
  message: string;
  code: number;
  data: ProductModel;
}

export interface RemoveAttributeData {
  attribute: Attribute;
}

export interface RemoveResponse {
  success: boolean;
  message: string;
  code: number;
  data: any;
}

export interface ProductStatusResponse {
  success: boolean;
  message: string;
  code: number;
  data: { status: PRODUCT_STATUS };
}

export interface ProductHotResponse {
  success: boolean;
  message: string;
  code: number;
  data: { hotProduct: boolean };
}

export interface ProductNewResponse {
  success: boolean;
  message: string;
  code: number;
  data: { newProduct: boolean };
}

export const SearchProduct = (
  searchPayload: ProductSearchRequest,
  page?: number,
  limit?: number,
): Promise<ProductSearchResponse> =>
  httpApi
    .post<ProductSearchResponse>(`products/search?page=${page}&limit=${limit}`, searchPayload)
    .then(({ data }) => data);

export const UpdateProductStatus = (id: number, status: PRODUCT_STATUS): Promise<ProductStatusResponse> =>
  httpApi.post<ProductStatusResponse>(`/products/${id}/change/status`, { status }).then(({ data }) => data);

export const UpdateProductHot = (id: number, hotProduct: boolean): Promise<ProductHotResponse> =>
  httpApi.post<ProductHotResponse>(`/products/${id}/change/hot-product`, { hotProduct }).then(({ data }) => data);

export const UpdateProductNew = (id: number, newProduct: boolean): Promise<ProductNewResponse> =>
  httpApi.post<ProductNewResponse>(`/products/${id}/change/new-product`, { newProduct }).then(({ data }) => data);

export const GetProduct = (uuid: string): Promise<ProductResponse> =>
  httpApi.get<ProductResponse>(`/products/show/${uuid}`).then(({ data }) => data);

export const UpdateProduct = (id: number, updateData: any): Promise<ProductResponse> =>
  httpApi
    .post<ProductResponse>(`/products/update/${id}`, updateData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);

export const CreateProduct = (updateData: FormData): Promise<ProductResponse> =>
  httpApi
    .post<ProductResponse>(`/products/create`, updateData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);

export const RemoveImage = (product_id: number, id: number): Promise<RemoveResponse> =>
  httpApi.delete(`/products/${product_id}/remove-image/${id}`).then(({ data }) => data);

export const RemoveVideo = (product_id: number, id: number): Promise<RemoveResponse> =>
  httpApi.delete(`/products/${product_id}/remove-video/${id}`).then(({ data }) => data);

export const RemoveFile = (product_id: number, id: number): Promise<RemoveResponse> =>
  httpApi.delete(`/products/${product_id}/remove-file/${id}`).then(({ data }) => data);

export const RemoveAttribute = (product_id: number, removeData: RemoveAttributeData): Promise<RemoveResponse> =>
  httpApi.put(`/products/${product_id}/remove-attribute`, removeData).then(({ data }) => data);
