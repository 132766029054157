import { httpApi } from '@app/api/http.api';
import { InquiryModel } from '@app/domain/Inquiry.model';
import { InvoiceModel } from '@app/domain/invoice.model';

export interface PreInvoiceCreateRequest {
  user: number;
  address: number;
  discount: string;
  shippingCost: string;
  vat: string;
  status: string;
  description: string;
  invoice: number;
  version: string;
  prices: Array<{
    id: number;
    price: number;
    discount: number;
    quantity: number;
    sku: string;
    title: string;
  }>;
}

export interface PreInvoiceCreateResponse {
  success: boolean;
  message: string;
  code: number;
  data: InquiryModel;
}

export interface InvoiceRequest {
  page: number;
}

export interface InvoiceResponse {
  success: boolean;
  message: string;
  code: number;
  data: {
    count: number;
    invoices: InvoiceModel[];
  };
}

export interface InvoiceVersionResponse {
  success: boolean;
  message: string;
  code: number;
  data: InvoiceModel[];
}

export interface invoiceSearchRequest {
  email?: string;
  code?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
}

export const Invoices = (listPayload: InvoiceRequest): Promise<InvoiceResponse> =>
  httpApi.get<InvoiceResponse>('invoices?page=' + listPayload.page).then(({ data }) => data);

export const TodayInvoices = (listPayload: InvoiceRequest): Promise<InvoiceResponse> =>
  httpApi.get<InvoiceResponse>('invoices/today?page=' + listPayload.page).then(({ data }) => data);

export const InvoiceVersions = (id: number): Promise<InvoiceVersionResponse> =>
  httpApi.get<InvoiceVersionResponse>('invoices/versions/' + id).then(({ data }) => data);

export const StorePreInvoice = (createPayload: PreInvoiceCreateRequest): Promise<PreInvoiceCreateResponse> =>
  httpApi.post<PreInvoiceCreateResponse>('invoices/create', createPayload).then(({ data }) => data);

export const SearchInvoices = (searchPayload: invoiceSearchRequest): Promise<InvoiceVersionResponse> =>
  httpApi.post<InvoiceVersionResponse>('invoices/search', searchPayload).then(({ data }) => data);
