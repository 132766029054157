import React, { useEffect, useRef, useState } from 'react';
import { Select, FormItem, FormInput, ButtonWrapper, UpdateButton, Note } from '../SingleProductPage.style';
import { Attributes, fetchAttributeById } from '@app/api/Attribute.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { Attribute, CategoryShowModel, ProductFormData } from '@app/constants/productDetails';
import { AttributeModel } from '@app/domain/Attribute.model';
import _set from 'lodash/set';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { ProductModel } from '@app/domain/Product.model';
import { RemoveAttribute } from '@app/api/Product.api';

interface AttributesProps {
  product: ProductModel;
  formDataAttributes: Attribute[];
  setFormData: React.Dispatch<React.SetStateAction<ProductFormData>>;
  getMainCategories: () => CategoryShowModel[];
}

const AttributesStep: React.FC<AttributesProps> = ({ product, formDataAttributes, setFormData, getMainCategories }) => {
  const { t } = useTranslation();
  const [attributes, setAttributes] = useState<AttributeModel[]>([]);
  const [mainCategory, setMainCategory] = useState<CategoryShowModel>();
  const productAttributes = product?.attributes;

  const getAttributes = () => {
    setAttributes([]);
    Attributes()
      .then((result) => {
        if (result.success) {
          setAttributes(result.data.attributes);
        } else {
          notificationController.error({ message: result.message });
        }
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });
  };

  useEffect(() => {
    getAttributes();
    setMainCategory(getMainCategories()[0]);
  }, []);

  const onChange = (updatedAttributes) => setFormData((prev) => ({ ...prev, attributes: updatedAttributes }));

  const fetchAttributeDetails = async (attributeId: number) => {
    try {
      const result = await fetchAttributeById(attributeId);
      if (result.success) {

        return result.data;
      } else {
        notificationController.error({ message: result.message });
      }
    } catch (error: any) {
      notificationController.error({ message: error.message });
    }
  };

  const handleAddAttribute = async (id: number, index: number) => {
    const data = await fetchAttributeDetails(id);

    const updatedAttributes = [...formDataAttributes];

    updatedAttributes[index] = data as unknown as Attribute;
    onChange(updatedAttributes);
  }

  const handleAttributeChange = (parentIndex: number, path: string, value: any) => {
    const updatedAttributes = [...formDataAttributes];
    _set(updatedAttributes[parentIndex], path, value);
    onChange(updatedAttributes);
  };

  const handleRemoveAttribute = async (index: number, attribute?: Attribute) => {
    if(attribute && productAttributes?.some(q => q.id === attribute?.id)) {
      const result = await RemoveAttribute(product?.id, { attribute: attribute });
      if(result.success) {
        notificationController.success({message: result.message});
      } else {
        notificationController.error({message: result.message});
      }
    }
    
    const updatedAttributes = formDataAttributes.filter((_, i) => i !== index);
    onChange(updatedAttributes);
  };

  const handleAddAttributePlace = () => {
    setFormData((prev) => {
      return { ...prev, attributes: [...prev.attributes, { id: 0, title: '', value: '', valueId: 0, items: [] }] };
    });
  };

  const handleSubmitDeleteRef = useRef<(() => void) | null>(null);
  const [destroyModal, setDestroyModal] = useState(false);

  const handleSetDestroyModal = (index: number, attribute?: Attribute) => {
    setDestroyModal(true);
      handleSubmitDeleteRef.current = () => handleRemoveAttribute(index, attribute);
  };

  const handleSubmitDelete = () => {
    // Call the function stored in the ref, if it exists
    if (handleSubmitDeleteRef.current) {
      handleSubmitDeleteRef.current();
    }
    setDestroyModal(false); // Close the modal after the action
  };

  return (
    <div>
      <BaseModal
        title={t('attributes.delete')}
        visible={destroyModal}
        centered
        size="large"
        onOk={handleSubmitDelete}
        onCancel={() => setDestroyModal(false)}
        cancelText={t('common.close')}
        okText={t('attributes.delete')}
      >
        <p>{t('attributes.delete-item-sure')}</p>
      </BaseModal>
      <Note>Attributes of <strong>third</strong> level with empty value will not be shown on application</Note>
      {formDataAttributes.map((attribute, index) => (
        <div key={attribute.id}>
          {/* First Level: Category Attribute */}
          <FormItem label={t('attributes.mainTitle')}>
            <Select
              value={attribute.id}
              options={[
                { label: t('common.none'), value: 0 },
                ...attributes.filter(q => q.category.id === mainCategory?.value).map((_attribute) => ({
                  label: _attribute.title,
                  value: _attribute.id,
                  disabled: productAttributes?.some((q) => q.id === _attribute.id),
                })),
              ]}
              disabled={productAttributes?.some((q) => q.id === attribute.id)}
              onChange={(value) => handleAddAttribute(value as number, index)}
            />
          </FormItem>

          {/* Second Level: Main Attribute */}
          {attribute.items?.map((attr, _index) => (
            <div key={attr.id} style={{ marginLeft: '2rem' }}>
              <FormItem label={attr.title}>
                <FormInput
                  value={attr.value}
                  placeholder={t('attributes.value')}
                  type="text"
                  onChange={(e) => handleAttributeChange(index, `items[${_index}].value`, e.target.value)}
                />
              </FormItem>
              {attr.items?.map((_attr, i) => (
                <div key={_attr.id} style={{ marginLeft: '2rem' }}>
                  <FormItem label={_attr.title}>
                    <FormInput
                      value={_attr.value}
                      placeholder={t('attributes.value')}
                      type="text"
                      onChange={(e) =>
                        handleAttributeChange(index, `items[${_index}].items[${i}].value`, e.target.value)
                      }
                    />
                  </FormItem>
                </div>
              ))}
            </div>
          ))}

          <ButtonWrapper style={{marginBottom: '2rem'}}>
            <BaseButton type="ghost" danger onClick={() => handleSetDestroyModal(index, attribute)}>
              {t('common.remove')}
            </BaseButton>
          </ButtonWrapper>
        </div>
      ))}

      <ButtonWrapper>
        <BaseButton type="ghost" onClick={() => handleAddAttributePlace()}>
          {t('common.add')}
        </BaseButton>
      </ButtonWrapper>
    </div>
  );
};

export default AttributesStep;
