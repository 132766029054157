import { httpApi } from '@app/api/http.api';
import { AttributeRow } from '@app/interfaces/attribute.interface';
import { AttributeModel } from '@app/domain/Attribute.model';

export interface AttributeRequest {
  page: number;
}

export interface AttributesResponse {
  success: boolean;
  message: string;
  code: number;
  data: {
    attributes: AttributeModel[];
    count: number;
  };
}

export interface AttributeResponse {
  success: boolean;
  message: string;
  code: number;
  data: AttributeModel;
}

export interface AttributeDeleteResponse {
  success: boolean;
  message: string;
  code: number;
  data: AttributeModel;
}

export interface AttributeCreateRequest {
  title: string;
  inputs: AttributeRow[];
  category: { label: string, value: number};
}

export const Attributes = (attributePayload?: AttributeRequest): Promise<AttributesResponse> =>
  httpApi.get<AttributesResponse>('products/attributes?page=' + attributePayload?.page).then(({ data }) => data);

export const fetchAttributeByUuid = (uuid: string): Promise<AttributeResponse> =>
  httpApi.get<AttributeResponse>('products/attributes/' + uuid).then(({ data }) => data);

export const fetchAttributeById = (id: number): Promise<AttributeResponse> =>
  httpApi.get<AttributeResponse>('products/attributes/show/' + id).then(({ data }) => data);

export const StoreAttribute = (attribute: AttributeCreateRequest): Promise<AttributesResponse> =>
  httpApi
    .post<AttributesResponse>('products/attributes/create', attribute)
    .then(({ data }) => data);

export const DeleteAttribute = (itemId: number | undefined, id: number): Promise<AttributeDeleteResponse> =>
  httpApi.get<AttributeDeleteResponse>('products/attributes/destroy/' + itemId + '/' + id).then(({ data }) => data);

export const UpdateAttribute = (id: number, attribute: AttributeCreateRequest): Promise<AttributesResponse> =>
  httpApi
    .post<AttributesResponse>('products/attributes/update/' + id, attribute)
    .then(({ data }) => data);
