import { ProductModel } from '@app/domain/Product.model';
import React from 'react';

export const ProductTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
  },
  {
    title: t('products.thumbnail'),
    dataIndex: 'thumbnail',
    render: (thumbnail: string) => <img src={thumbnail} alt="thumbnail" width={50} />,
  },
  {
    title: t('products.title'),
    dataIndex: 'title',
  },
  {
    title: t('products.sku'),
    dataIndex: 'sku',
  },
  {
    title: t('products.status'),
    dataIndex: 'status',
    render: (status: string) => (
      <span className={status === 'active' ? 'label label-success' : 'label label-danger'}>{status}</span>
    ),
  },
  {
    title: t('products.brand'),
    dataIndex: 'brand',
    render: (brand: string) => (brand ? brand : '---'),
  },
  {
    title: t('products.categories'),
    dataIndex: 'categories',
    render: (categories: { title: string }[]) =>
      categories && categories.length > 0 ? categories.map((cat) => cat.title).join(', ') : '---', // Render category titles
  },
  {
    title: t('products.actions'),
    dataIndex: 'actions',
    width: '15%',
    render: (_: any, record: any) => record.actions,
  },
];

export interface ProductData {
  index: number;
  thumbnail: string;
  title: string;
  sku: string;
  status: JSX.Element;
  brand: string; // Brand as string (brand name)
  categories: { title: string }[]; // Use 'title' instead of 'name' for consistency
  actions: JSX.Element;
}
