import React, { useEffect, useState } from 'react';
import * as S from './AttributePage.style';
import { useTranslation } from 'react-i18next';
import { AttributeData, AttributeTableColumns } from '@app/interfaces/attribute.interface';
import { TablePagination } from '@app/interfaces/table.interface';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { Attributes } from '@app/api/Attribute.api';
import { AttributeModel } from '@app/domain/Attribute.model';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const AttributePage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [attributes, setAttributes] = useState<AttributeData[]>([]);
  const [pagination, setPagination] = useState<TablePagination>({ current: 1, pageSize: 10, total: 1 });
  const navigate = useNavigate();

  const getAttributes = () => {
    setAttributes([]);
    Attributes({ page: pagination.current })
      .then((result) => {
        if (result.success) {
          const attributesData: AttributeData[] = result.data.attributes.map(
            (attribute: AttributeModel, index: number) => ({
              index: ++index,
              title: attribute.title,
              category: attribute.category.title,
              actions: (
                <BaseSpace>
                  <BaseButton type="ghost" onClick={() => handleOpenAttribute(attribute)}>
                    {t('common.update')}
                  </BaseButton>
                </BaseSpace>
              ),
            }),
          );
          setAttributes(attributesData);

          setPagination({
            current: pagination.current,
            pageSize: 10,
            total: result.data.count,
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  useEffect(() => {
    getAttributes();
  }, []);

  const handleOpenAttribute = (attribute: AttributeModel) => {
    navigate('/products/attributes/' + attribute.uuid, { state: attribute });
  };

  const handleTableChange = (pagination: TablePagination) => {
    setPagination({
      current: pagination.current++,
      pageSize: pagination.pageSize,
      total: pagination.total,
    });
    getAttributes();
  };

  return (
    <>
      <S.Card title={t('attributes.attributes')} padding="1.25rem 1.25rem 0">
        <PageTitle>Attributes</PageTitle>
        <S.TablesWrapper>
          <S.Table
            scroll={{ x: 800 }}
            columns={AttributeTableColumns(t)}
            dataSource={attributes}
            loading={loading}
            pagination={pagination}
            onChange={() => handleTableChange(pagination)}
          />
        </S.TablesWrapper>
      </S.Card>
    </>
  );
};

export default AttributePage;
