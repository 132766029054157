import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './InvoicePage.style';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { SearchUser, UserAddress } from '@app/api/User.api';
import { notificationController } from '@app/controllers/notificationController';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { UserModal } from '@app/domain/User.model';
import {
  UserAddressData,
  UserAddressTableColumns,
  UserSearchData,
  UserSearchTableColumns,
} from '@app/interfaces/user.interface';
import { EditableCell } from './EditableCell';
import { InquiryProductData, InquiryProductTableColumns } from '@app/interfaces/inquiry.interface';
import { ProductSearchRequest, SearchProduct } from '@app/api/Product.api';
import { InquiryCreateRequest, StoreInquiry } from '@app/api/Inquiry.api';
import { useNavigate } from 'react-router-dom';
import { UserAddressModel } from '@app/domain/UserAddresssModel';
import { PreInvoiceCreateRequest, StorePreInvoice } from '@app/api/Invoice.api';
import { useLocation } from 'react-router';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export let initValues = {
  title: '',
  user: '',
  address: '',
  totalPrice: '',
  shippingCost: '',
  discount: '',
  vat: '',
  status: 'pre_invoice',
  products: [{ title: '', price: '', discount: '', quantity: 1, description: '' }],
  description: '',
};

const ShowInvoicePage: React.FC = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserSearchData[]>([]);
  const [addresses, setAddresses] = useState<UserAddressData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<InquiryProductData[]>([]);
  const location = useLocation();

  useEffect(() => {
    setUsers((users) => [
      ...users,
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        index: location.state.user.id,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        firstname: location.state.user.firstName,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lastname: location.state.user.lastName,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        email: location.state.user.email,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        status: location.state.status,
        action: <BaseSpace></BaseSpace>,
      },
    ]);

    setAddresses((addresses) => [
      ...addresses,
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        index: location.state.address.id,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        firstname: location.state.address.firstName,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lastname: location.state.address.lastName,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        address: location.state.address.address,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        city: location.state.address.city.title,
        action: <BaseSpace></BaseSpace>,
      },
    ]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    location.state.products.map((product, index) => {
      setProducts((products) => [
        ...products,
        {
          id: product.id,
          index: product.id,
          title: product.title,
          sku: product.code,
          quantity: product.quantity,
          price: product.price ? product.price : product.uPrice,
          discount: product.discount,
          description: product.description,
          actions: <></>,
        },
      ]);
    });

    initValues = {
      title: location.state.title!,
      address: location.state.address!,
      products: location.state.products!,
      status: location.state.status!,
      user: location.state.user!,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      totalPrice: location.state.totalPrice,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      shippingCost: location.state.shippingCost,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      discount: location.state.discount,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      vat: location.state.vat,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      description: location.state.description,
    };
  }, []);

  return (
    <>
      <PageTitle>Invoices</PageTitle>
      <S.Card title={t('invoices.show-pre-invoice')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <BaseForm
            style={{ width: '100%', margin: '0 auto 0 auto' }}
            layout="vertical"
            requiredMark="optional"
            initialValues={initValues}
          >
            <S.Table scroll={{ x: 800 }} columns={UserSearchTableColumns(t)} dataSource={users} loading={loading} />
            <S.Table
              scroll={{ x: 800 }}
              columns={UserAddressTableColumns(t)}
              dataSource={addresses}
              loading={loading}
            />
            <S.InputsWrapper>
              <S.FormItem
                style={{ width: '100%' }}
                name="discount"
                label={t('invoices.discount')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <S.FormInput
                  placeholder={t('invoices.discount')}
                  onChange={(target) => (initValues.discount = target.target.value)}
                />
              </S.FormItem>
              <S.FormItem
                style={{ width: '100%' }}
                name="shippingCost"
                label={t('invoices.shippingCost')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <S.FormInput
                  placeholder={t('invoices.shippingCost')}
                  onChange={(target) => (initValues.shippingCost = target.target.value)}
                />
              </S.FormItem>
            </S.InputsWrapper>
            <S.InputsWrapper>
              <S.FormItem
                style={{ width: '100%' }}
                name="vat"
                label={t('invoices.vat')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <S.FormInput
                  placeholder={t('invoices.vat')}
                  onChange={(target) => (initValues.vat = target.target.value)}
                />
              </S.FormItem>
              <S.FormItem
                style={{ width: '100%' }}
                name="status"
                label={t('invoices.status')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <BaseSelect width={120} onChange={(value: any) => (initValues.status = value)}>
                  <Option value="pre_invoice">{t('invoices.preInvoice')}</Option>
                  <Option value="final">{t('invoices.final')}</Option>
                </BaseSelect>
              </S.FormItem>
            </S.InputsWrapper>
            <S.FormItem
              style={{ width: '100%' }}
              name="description"
              label={t('invoices.description')}
              rules={[{ required: false, message: t('common.requiredField') }]}
            >
              <S.FormInputLong
                rows={10}
                placeholder={t('invoices.description')}
                onChange={(target) => (initValues.description = target.target.value)}
              />
            </S.FormItem>

            <S.Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={products}
              columns={InquiryProductTableColumns(t)}
              rowClassName="editable-row"
              loading={loading}
              scroll={{ x: 800 }}
            />
          </BaseForm>
        </S.TablesWrapper>
      </S.Card>
    </>
  );
};

export default ShowInvoicePage;
