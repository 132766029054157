import React from 'react';

export const FileIcon: React.FC = () => {
  return (
    <svg width="200" height="200" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 0.5H5.5C4.67157 0.5 4 1.17157 4 2V17C4 17.8284 4.67157 18.5 5.5 18.5H13.5C14.3284 18.5 15 17.8284 15 17V6.5L13.5 0.5Z"
        // fill="currentColor"
      />
      <path d="M13.5 0.5V6.5H15L13.5 0.5Z" />
    </svg>
  );
};
