import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '@app/pages/Settings/Countries/CountryPage.style';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Invoices, TodayInvoices } from '@app/api/Invoice.api';
import { InvoiceModel } from '@app/domain/invoice.model';
import { InvoiceData, InvoiceTableColumns } from '@app/interfaces/invoice.interface';
import { TablePagination } from '@app/interfaces/table.interface';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const TodayInvoicePage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<InvoiceData[]>([]);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<TablePagination>({ current: 1, pageSize: 10, total: 1 });

  useEffect(() => {
    setInvoices([]);
    TodayInvoices({ page: pagination.current })
      .then((result) => {
        if (result.success) {
          result.data.invoices.map((invoice, index) => {
            setInvoices((invoices) => {
              return [
                ...invoices,
                {
                  id: invoice.id,
                  index: invoice.id,
                  code: invoice.code,
                  status: invoice.status,
                  version: invoice.version,
                  totalPrice: invoice.totalPrice,
                  createdAt: moment(invoice.createdAt).format('Y-M-D h:i:s'),
                  user: invoice.user.email,
                  actions: (
                    <BaseSpace>
                      <BaseButton type="ghost" onClick={() => handleOpenShow(invoice)}>
                        {t('common.show')}
                      </BaseButton>
                      <BaseButton type="ghost" onClick={() => handleOpenVersions(invoice)}>
                        {t('inquiries.versions')}
                      </BaseButton>
                    </BaseSpace>
                  ),
                },
              ] as InvoiceData[];
            });
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  }, []);

  const handleOpenShow = (invoice: InvoiceModel) => {
    console.log(invoice);
    navigate('/pre-invoices/' + invoice.uuid, { state: invoice });
  };

  const handleOpenVersions = (inquiry: InvoiceModel) => {
    navigate('/pre-invoices/' + inquiry.uuid + '/versions', { state: inquiry });
  };

  return (
    <>
      <PageTitle>Invoices</PageTitle>
      <S.Card title={t('invoices.today-invoices')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <S.FormItem style={{ width: '100%' }} name="invoices" label={t('invoices.code')}>
            <S.FormInput
              placeholder={t('invoices.code')}
              onChange={(target) => {
                setInvoices(invoices.filter((item) => item.code.includes(target.target.value)));
              }}
            />
          </S.FormItem>

          <S.Table scroll={{ x: 800 }} columns={InvoiceTableColumns(t)} dataSource={invoices} loading={loading} />
        </S.TablesWrapper>
      </S.Card>
    </>
  );
};
export default TodayInvoicePage;
