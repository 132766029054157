import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductTableColumns, ProductData } from '@app/interfaces/product.interface';
import { notificationController } from '@app/controllers/notificationController';
import { SearchProduct, UpdateProductHot, UpdateProductNew, UpdateProductStatus } from '@app/api/Product.api';
import { ProductModel } from '@app/domain/Product.model';
import { TablePagination } from '@app/interfaces/table.interface';
import * as S from './ProductPage.style';
import { Dropdown, Menu, TablePaginationConfig } from 'antd';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { PRODUCT_STATUS } from '@app/constants/enums/product.status';
import { CategoryModel } from '@app/domain/CategoryModel';
import { Categories } from '@app/api/Category.api';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export const ProductList: React.FC = () => {
  const { t } = useTranslation();

  const [products, setProducts] = useState<ProductModel[]>([]);
  const [displayedProducts, setDisplayedProducts] = useState<ProductData[]>([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<TablePagination>({ current: 1, pageSize: 10, total: 1 });
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductModel | null>(null);
  const [status, setStatus] = useState<PRODUCT_STATUS>(PRODUCT_STATUS.ACTIVE);

  const [categoryOptions, setCategoryOptions] = useState<{ label: string; value: number }[]>([]);

  const [searchParams, setSearchParams] = useState({
    title: '',
    brand: '',
    sku: '',
    status: undefined as PRODUCT_STATUS | undefined,
    category: undefined as number | undefined,
    isStock: '',
  });

  const fetchCategories = useCallback(() => {
    Categories({ type: 'product', page: 1 })
      .then((response) => {
        if (response.success) {
          setCategoryOptions(
            response.data.categories.map((category: CategoryModel) => ({
              label: category.title,
              value: category.id,
            })),
          );
        } else {
          notificationController.error({ message: response.message });
        }
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });
  }, []);

  const fetchProducts = useCallback(
    (page = pagination.current, limit = pagination.pageSize) => {
      setLoading(true);
      SearchProduct({ ...searchParams }, page, limit)
        .then((response) => {
          if (response.success) {
            const productData: ProductData[] = response.data.products.map((product: ProductModel, index: number) => ({
              key: product.id,
              index: index + 1,
              thumbnail: product.thumbnail,
              title: product.title,
              sku: product.sku,
              status: (
                <BaseSpace>
                  {product.status}
                  <BaseButton type="ghost" onClick={() => handleStatusEdit(product)}>
                    {t('common.update')}
                  </BaseButton>
                </BaseSpace>
              ),
              brand: product.brand?.title ?? 'N/A',
              categories: product.categories?.map((category) => ({ title: category.title })),
              actions: (createAction(product)),
            }));

            setProducts(response.data.products);
            setDisplayedProducts(productData);
            setPagination({ current: page, pageSize: limit, total: response.data.totalCount });
          } else {
            notificationController.error({ message: response.message });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          notificationController.error({ message: error.message });
        });
    },
    [pagination, searchParams],
  );

  const createAction = (product: ProductModel) => {
    const menu = (
      <Menu>
        {/* Edit Product */}
        <Menu.Item key="edit">
          <BaseButton type="ghost" style={{ width: '200px' }} onClick={() => handleEditProduct(product)}>
            {t('common.update')}
          </BaseButton>
        </Menu.Item>

        {/* Hot Product Toggle */}
        <Menu.Item key="hot-product">
          <BaseButton
            type="ghost"
            danger={product.hotProduct}
            style={{ width: '200px' }}
            onClick={() => {
              setProductToUpdate({ field: 'hotProduct', mark: !product.hotProduct });
              handleSetDestroyModal('hotProduct', product);
            }}
          >
            {product.hotProduct ? t('products.remove-hot-product') : t('products.add-hot-product')}
          </BaseButton>
        </Menu.Item>

        {/* New Product Toggle */}
        <Menu.Item key="new-product">
          <BaseButton
            type="ghost"
            danger={product.newProduct}
            style={{ width: '200px' }}
            onClick={() => {
              setProductToUpdate({ field: 'newProduct', mark: !product.newProduct });
              handleSetDestroyModal('newProduct', product);
            }}
          >
            {product.newProduct ? t('products.remove-new-product') : t('products.add-new-product')}
          </BaseButton>
        </Menu.Item>
      </Menu>
    );

    return (
      <BaseSpace>
        <Dropdown overlay={menu} trigger={['click']}>
          <BaseButton style={{ width: '200px' }}>
            {t('common.actions')}
          </BaseButton>
        </Dropdown>
      </BaseSpace>
    );
  }

  const handleStatusEdit = (product: ProductModel) => {
    setSelectedProduct(product);
    setStatus(product.status);
    setEditModalVisible(true);
  };

  const navigator = useNavigate();
  const handleEditProduct = (product: ProductModel) => {
    navigator(`/products/${product.uuid}`);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const page = pagination.current ?? 1;
    const limit = pagination.pageSize ?? 10;
    setPagination({
      ...pagination,
      current: pagination.current ?? 1,
    } as TablePagination);
    fetchProducts(page, limit);
  };

  const handleModalSubmit = () => {
    if (selectedProduct) {
      selectedProduct.status = status;
      UpdateProductStatus(selectedProduct.id, status);
      setEditModalVisible(false);
    }
  };

  const handleSearchSubmit = () => {
    fetchProducts(1, pagination.pageSize);
  };

  const handleSearchParamChange = (
    key: keyof typeof searchParams,
    value: string | number | PRODUCT_STATUS | undefined,
  ) => {
    setSearchParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  useEffect(() => {
    fetchProducts(pagination.current, pagination.pageSize);
  }, [searchParams, selectedProduct?.status]);

  const handleUpdateHotProduct = (id: number, hotProduct: boolean) => {
    UpdateProductHot(id, hotProduct);
  };

  const handleUpdateNewProduct = (id: number, newProduct: boolean) => {
    UpdateProductNew(id, newProduct);
  };

  const [productToUpdate, setProductToUpdate] = useState<{ field: string; mark: boolean }>();
  const [destroyModal, setDestroyModal] = useState(false);
  const handleSubmitDeleteRef = useRef<(() => void) | null>(null);

  const handleSetDestroyModal = (field: string, product: ProductModel) => {
    setDestroyModal(true);

    if (field === 'hotProduct') {
      // Define the function to call `handleClearImage`, but do not execute it yet
      handleSubmitDeleteRef.current = () => handleUpdateHotProduct(product.id, !product.hotProduct);
    } else if (field === 'newProduct') {
      handleSubmitDeleteRef.current = () => handleUpdateNewProduct(product.id, !product.newProduct);
    }
  };

  const handleSubmitDelete = () => {
    // Call the function stored in the ref, if it exists
    if (handleSubmitDeleteRef.current) {
      handleSubmitDeleteRef.current();
      fetchProducts();
    }
    setDestroyModal(false); // Close the modal after the action
  };

  return (
    <S.TablesWrapper>
      <PageTitle>Products</PageTitle>
      <S.SearchForm>
        <S.FormItem label={t('products.title')}>
          <S.FormInput value={searchParams.title} onChange={(e) => handleSearchParamChange('title', e.target.value)} />
        </S.FormItem>
        <S.FormItem label={t('products.brand')}>
          <S.FormInput value={searchParams.brand} onChange={(e) => handleSearchParamChange('brand', e.target.value)} />
        </S.FormItem>
        <S.FormItem label={t('products.sku')}>
          <S.FormInput value={searchParams.sku} onChange={(e) => handleSearchParamChange('sku', e.target.value)} />
        </S.FormItem>
        <S.FormItem label={t('products.status')}>
          <S.Select
            value={searchParams.status}
            onChange={(value: unknown) => handleSearchParamChange('status', value as PRODUCT_STATUS)}
            defaultValue={t('common.none')}
            options={[
              { label: t('common.none'), value: '' },
              { label: t('common.active'), value: PRODUCT_STATUS.ACTIVE },
              { label: t('common.pending'), value: PRODUCT_STATUS.PENDING },
              { label: t('common.decline'), value: PRODUCT_STATUS.DECLINE },
            ]}
          />
        </S.FormItem>

        <S.FormItem label={t('products.categories')}>
          <S.Select
            value={searchParams.category}
            defaultValue={t('common.none')}
            onChange={(value: unknown) => handleSearchParamChange('category', value as number)}
            options={[{ label: t('common.none'), value: '' }, ...categoryOptions]}
          />
        </S.FormItem>
        <S.FormItem label={t('products.isStock')}>
          <S.Select
            value={searchParams.isStock}
            onChange={(value: unknown) => handleSearchParamChange('isStock', value as string)}
            defaultValue={t('common.none')}
            options={[
              { label: t('common.none'), value: '' },
              { label: t('common.inStock'), value: 'in stock' },
              { label: t('common.outOfStock'), value: 'out of stock' },
            ]}
          />
        </S.FormItem>
        <S.SearchButton type="primary" onClick={handleSearchSubmit}>
          {t('common.search')}
        </S.SearchButton>
      </S.SearchForm>

      <S.Card title={t('products.productList')} padding="1.25rem">
        <S.Table
          columns={ProductTableColumns(t)}
          dataSource={displayedProducts}
          loading={loading}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </S.Card>

      <BaseModal
        title={t('common.update')}
        visible={destroyModal}
        centered
        size="large"
        onOk={handleSubmitDelete}
        onCancel={() => setDestroyModal(false)}
        cancelText={t('common.close')}
        okText={t('common.update')}
      >
        <p>
          {productToUpdate?.mark ? t('products.mark') : t('products.unmark')} {productToUpdate?.field}?
        </p>
      </BaseModal>

      <BaseModal
        title={t('products.updateStatus')}
        visible={editModalVisible}
        centered
        size="large"
        onOk={handleModalSubmit}
        onCancel={() => setEditModalVisible(false)}
        cancelText={t('common.close')}
        okText={t('common.update')}
      >
        {selectedProduct ? (
          <S.Form layout="vertical">
            <S.FormItem label={t('products.title')}>
              <S.FormInput value={selectedProduct.title} disabled />
            </S.FormItem>
            <S.FormItem label={t('products.sku')}>
              <S.FormInput value={selectedProduct.sku} disabled />
            </S.FormItem>
            <S.FormItem label={t('products.brand')}>
              <S.FormInput value={selectedProduct.brand?.title ?? 'N/A'} disabled />
            </S.FormItem>
            <S.FormItem label={t('products.categories')}>
              <S.FormInput
                value={selectedProduct.categories?.map((category) => category.title).join(', ') ?? 'N/A'}
                disabled
              />
            </S.FormItem>
            <S.FormItem
              name="status"
              label={t('categories.status')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.RadioGroup
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                defaultValue={selectedProduct.status}
              >
                <S.RadioButton value={PRODUCT_STATUS.ACTIVE}>{t('common.active')}</S.RadioButton>
                <S.RadioButton value={PRODUCT_STATUS.PENDING}>{t('common.pending')}</S.RadioButton>
                <S.RadioButton value={PRODUCT_STATUS.DECLINE}>{t('common.decline')}</S.RadioButton>
              </S.RadioGroup>
            </S.FormItem>
          </S.Form>
        ) : null}
      </BaseModal>
    </S.TablesWrapper>
  );
};

export default ProductList;
