import React from 'react';
import * as S from './styles.style';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { PageModel } from '@app/domain/Page.model';

interface Props {
  formData: PageModel;
  setFormData: React.Dispatch<React.SetStateAction<PageModel>>;
  updateProduct: (formData: PageModel) => void;
}

export const PageForm = ({ formData, setFormData, updateProduct }: Props) => {
  const { t } = useTranslation();
  const onChange = (updatedData: Partial<PageModel>) => setFormData({ ...formData, ...updatedData });
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    onChange({ [name]: value });
  };

  const handleContentChange = (value: any) => {
    onChange({ content: value });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }], // Dropdown with color options
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }], // Indentation
      [{ align: [] }], // Text alignment
      ['link', 'image', 'video'], // Add links, images, videos
      ['clean'], // Remove formatting
    ],
  };

  return (
    <>
      <S.FormItem>
        <label>{t('products.title')}</label>
        <S.FormInput name="title" defaultValue={formData.title} onChange={handleInputChange} />
      </S.FormItem>
      <S.FormItem>
        <label>{t('pages.content')}</label>
        {/* <Input.TextArea name="description" defaultValue={formData.description} onChange={handleInputChange} /> */}
        <ReactQuill theme="snow" modules={modules} value={formData.content} onChange={(value) => handleContentChange(value)} />
      </S.FormItem>
      {formData.slug && (
        <S.FormItem>
          <label>{t('products.slug')}</label>
          <S.FormInput name="slug" defaultValue={formData.slug} disabled onChange={handleInputChange} />
        </S.FormItem>
      )}
      <BaseSpace style={{ justifyContent: 'flex-end' }}>
          <BaseButton type="ghost" onClick={() => updateProduct(formData)}>
            {t('common.submit')}
          </BaseButton>
      </BaseSpace>
    </>
  );
};
