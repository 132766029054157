import React, { useState } from 'react';
import GeneralInfoForm from './Steps/GeneralInfoForm';
import AdditionalInfoForm from './Steps/AdditionalInfoForm';
import MediaForm from './Steps/MediaForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import * as S from './SingleProductPage.style';
import {
  BrandShowModel,
  CategoryOptionsModel,
  CategoryShowModel,
  ProductFormData,
  Tags,
  VideoGallery,
} from '@app/constants/productDetails';
import { BrandData } from '@app/interfaces/brand.interface';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { ProductModel } from '@app/domain/Product.model';
import { useTranslation } from 'react-i18next';
import Attributes from './Steps/Attributes';

interface ProductWizardFormProps {
  product?: ProductModel;
  formData: ProductFormData;
  setFormData: React.Dispatch<React.SetStateAction<ProductFormData>>;
  updateProduct: (formData: ProductFormData) => void;
  categoryOptions: CategoryOptionsModel[];
  brands: BrandData[];
  isUpdating: boolean;
  setProductAgain?: () => void;
}

const ProductWizardForm: React.FC<ProductWizardFormProps> = ({
  product,
  formData,
  setFormData,
  updateProduct,
  categoryOptions,
  brands,
  isUpdating = false,
  setProductAgain,
}) => {
  const [step, setStep] = useState(0);
  const [tagInput, setTagInput] = useState('');
  const { t } = useTranslation();

  const handleNext = () => setStep((prev) => prev + 1);
  const handleBack = () => setStep((prev) => prev - 1);
  const handleFormChange = (updatedData: Partial<ProductFormData>) => setFormData({ ...formData, ...updatedData });
  const handleCategorySelection = (selectedValues: CategoryShowModel[]) => {
    setFormData((prev) => ({
      ...prev,
      categories: selectedValues as CategoryShowModel[],
    }));
  };

  // Function to get the main categories from the selected list
  const getMainCategories = () => {
    // Extract the top-level IDs from the tree data
    const topLevelCategoryIds = categoryOptions.map((category) => category.value);

    // Filter selected categories to find which are top-level
    return formData.categories.filter((category) => topLevelCategoryIds.includes(category.value));
  };

  const handleBrandChange = (selectedValue: BrandShowModel) => {
    console.log(selectedValue);
    setFormData((prev) => ({
      ...prev,
      brand: selectedValue as BrandShowModel,
    }));
  };

  const handleTagInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && tagInput.trim() !== '') {
      e.preventDefault();
      setFormData((prev) => ({
        ...prev,
        tags: [...(prev.tags as Tags[]), { name: tagInput, id: 0 }],
      }));
      setTagInput('');
    }
  };

  const handleTagRemove = (id: number) => {
    setFormData((prev) => ({
      ...prev,
      tags: (prev.tags as Tags[]).filter((t) => t.id !== id),
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    if (e.target.files) {
      setFormData((prev) => ({
        ...prev,
        [fieldName]: Array.from(e.target.files as FileList),
      }));
    }
  };

  const handleVideoChange = (updatedVideos: VideoGallery[]) => {
    setFormData((prev) => ({ ...prev, videoGallery: updatedVideos }));
  };

  const handleFileGalleryChange = (updatedFiles: VideoGallery[]) => {
    setFormData((prev) => ({ ...prev, fileGallery: updatedFiles }));
  };

  const stepContents = [
    <GeneralInfoForm formData={formData} onChange={handleFormChange} />,
    <AdditionalInfoForm
      formData={formData}
      onChange={handleFormChange}
      categoryOptions={categoryOptions}
      handleTagInputChange={handleTagInputChange}
      handleTagInputKeyDown={handleTagInputKeyDown}
      handleTagRemove={handleTagRemove}
      handleCategorySelection={handleCategorySelection}
      handleBrandChange={handleBrandChange}
      tagInput={tagInput}
      brands={brands}
    />,
    <Attributes
      product={product!}
      formDataAttributes={formData.attributes}
      setFormData={setFormData}
      getMainCategories={getMainCategories}
    />,
    <MediaForm
      formData={formData}
      setFormData={setFormData}
      handleFileChange={handleFileChange}
      product={product}
      onVideoChange={handleVideoChange}
      onFileChange={handleFileGalleryChange}
      setProductAgain={setProductAgain && setProductAgain}
    />,
  ];

  const steps = [
    { title: t('products.general-info') },
    { title: t('products.additional-info') },
    { title: t('products.attributes') },
    { title: t('products.media') },
  ];

  return (
    <S.Form>
      <S.Steps size="small" current={step} items={steps} />
      {isUpdating ? formData.title && stepContents[step] : stepContents[step]}
      <BaseSpace style={{ justifyContent: 'flex-end' }}>
        {step > 0 && (
          <BaseButton type="ghost" onClick={handleBack}>
            {t('common.back')}
          </BaseButton>
        )}
        {step < stepContents.length - 1 ? (
          <BaseButton type="ghost" onClick={handleNext}>
            {t('common.next')}
          </BaseButton>
        ) : (
          <BaseButton type="ghost" onClick={() => updateProduct(formData)}>
            {t('common.submit')}
          </BaseButton>
        )}
      </BaseSpace>
    </S.Form>
  );
};

export default ProductWizardForm;
