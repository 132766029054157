import { HomeOutlined } from '@ant-design/icons';
import React from 'react';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'menu.dashboard',
    key: 'Dashboard',
    url: '/',
    icon: <HomeOutlined />,
  },
  {
    title: 'menu.products-management',
    key: 'Products-management',
  },
  {
    title: 'menu.categories',
    key: 'categories',
    url: '/products/categories',
    icon: <HomeOutlined />,
  },
  {
    title: 'menu.attributes',
    key: 'attributes',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'menu.new-attribute',
        key: 'newAttributes',
        url: '/products/attributes/new',
      },
      {
        title: 'menu.attributes-list',
        key: 'attributesList',
        url: '/products/attributes',
      },
    ],
  },
  {
    title: 'menu.variations',
    key: 'variations',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'menu.new-variation',
        key: 'newVariations',
        url: '/products/variations/new',
      },
      {
        title: 'menu.variations-list',
        key: 'variationsList',
        url: '/products/variations',
      },
    ],
  },
  {
    title: 'menu.brands',
    key: 'brands',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'menu.new-brand',
        key: 'newBrands',
        url: '/products/brands/new',
      },
      {
        title: 'menu.brands-list',
        key: 'brandsList',
        url: '/products/brands',
      },
    ],
  },
  {
    title: 'menu.products',
    key: 'Products',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'menu.new-product',
        key: 'newProduct',
        url: '/products/new',
      },
      {
        title: 'menu.products-list',
        key: 'productsList',
        url: '/products',
      },
    ],
  },
  {
    title: 'menu.orders-management',
    key: 'OrdersManagement',
  },
  {
    title: 'menu.inquiries',
    key: 'inquiries',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'menu.new-inquiry',
        key: 'newInquiry',
        url: '/inquiries/new',
      },
      {
        title: 'menu.inquires-list',
        key: 'inquiries',
        url: '/inquiries',
      },
      {
        title: 'menu.quick-buy',
        key: 'quick-buy',
        url: '/inquiries/quick-buy',
      },
      {
        title: 'menu.cart',
        key: 'cart-inquiry',
        url: '/inquiries/cart',
      },
      {
        title: 'menu.inquires-list-today',
        key: 'todayInquiries',
        url: '/inquiries/today',
      },
      {
        title: 'menu.inquires-search',
        key: 'searchInquiries',
        url: '/inquiries/search',
      },
    ],
  },
  {
    title: 'menu.pre-invoices',
    key: 'pre-invoices-menu',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'menu.new-pre-invoice',
        key: 'newPreInvoice',
        url: '/pre-invoices/new',
      },
      {
        title: 'menu.import-inquiry',
        key: 'importInquiry',
        url: '/pre-invoices/new/inquiry/import',
      },
      {
        title: 'menu.pre-invoice-list',
        key: 'pre-invoices',
        url: '/pre-invoices',
      },
      {
        title: 'menu.pre-invoice-list-today',
        key: 'today-invoices',
        url: '/pre-invoices/today',
      },
      {
        title: 'menu.pre-invoice-search',
        key: 'search-invoice',
        url: '/pre-invoices/search',
      },
    ],
  },
  {
    title: 'menu.orders',
    key: 'orders-menu',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'menu.new-order',
        key: 'newOrder',
        url: '/orders/new',
      },
      {
        title: 'menu.orders-list',
        key: 'orders',
        url: '/orders',
      },
      {
        title: 'menu.orders-list-today',
        key: 'todayOrders',
        url: '/orders/today',
      },
      {
        title: 'menu.orders-search',
        key: 'searchOrders',
        url: '/orders/search',
      },
    ],
  },
  {
    title: 'menu.general-settings',
    key: 'general',
  },
  {
    title: 'menu.countries',
    key: 'Countries',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'menu.new-country',
        key: 'newCountry',
        url: '/settings/countries/new',
      },
      {
        title: 'menu.country-list',
        key: 'countries',
        url: '/settings/countries',
      },
    ],
  },
  {
    title: 'menu.pages',
    key: 'Pages',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'menu.new-page',
        key: 'newPage',
        url: '/pages/new',
      },
      {
        title: 'menu.pages-list',
        key: 'pages',
        url: '/pages',
      },
    ],
  },
];
