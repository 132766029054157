import { httpApi } from '@app/api/http.api';
import { PageModel } from '@app/domain/Page.model';

export interface PagesGetResponse {
  success: boolean;
  message: string;
  code: number;
  data: PageModel[];
}

export interface PageResponse {
  success: boolean;
  message: string;
  code: number;
  data: PageModel;
}

export const GetAllPages = (): Promise<PagesGetResponse> =>
  httpApi
    .get<PagesGetResponse>('/pages')
    .then(({ data }) => data);

export const GetPage = (uuid: string): Promise<PageResponse> =>
  httpApi
    .get<PageResponse>(`/pages/${uuid}`)
    .then(({ data }) => data);

export const UpdatePage = (id: number, updateData: any): Promise<PageResponse> =>
  httpApi
    .put<PageResponse>(`/pages/${id}`, updateData)
    .then(({ data }) => data);

export const CreatePage = (updateData: any): Promise<PageResponse> =>
  httpApi
    .post<PageResponse>(`/pages`, updateData,)
    .then(({ data }) => data);
