export interface BrandData {
  index: number;
  title: string;
  status?: string;
  thumbnail?: JSX.Element;
  actions?: JSX.Element;
}

export const BrandTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('brands.thumbnail'),
    dataIndex: 'thumbnail',
    showSorterTooltip: true,
  },
  {
    title: t('brands.title'),
    dataIndex: 'title',
    showSorterTooltip: true,
  },
  {
    title: t('brands.status'),
    dataIndex: 'status',
    showSorterTooltip: true,
  },
  {
    title: t('brands.actions'),
    dataIndex: 'actions',
    width: '15%',
  },
];
