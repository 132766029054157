import React, { useState } from 'react';
import * as S from './VariationPage.style';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { VariationRaw } from '@app/interfaces/Variation.interface';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { notificationController } from '@app/controllers/notificationController';
import { StoreVariation } from '@app/api/Variation.api';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export const initValues = {
  title: '',
  type: '',
};

const NewVariationPage: React.FC = () => {
  const { t } = useTranslation();
  const [variationData, setVariationData] = useState<VariationRaw[]>([{ name: '' }]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmitCreate = (values: any) => {
    setLoading(true);

    StoreVariation(values.title, values.type, variationData)
      .then((result) => {
        if (result.success) {
          notificationController.success({ message: result.message });
          navigate('/products/variations');
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  const AddItem = () => {
    setVariationData([...variationData, { name: '' }]);
  };

  const RemoveItem = (index: number) => {
    const rows = [...variationData];
    rows.splice(index, 1);
    setVariationData(rows);
  };

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const list = [...variationData];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    list[index].name = value;
    setVariationData(list);
  };

  return (
    <>
      <S.Card title={t('variations.new-variation')} padding="1.25rem 1.25rem 0">
        <PageTitle>Variations</PageTitle>
        <BaseForm
          style={{ width: '70%', margin: '0 auto 0 auto' }}
          layout="vertical"
          onFinish={handleSubmitCreate}
          requiredMark="optional"
          initialValues={initValues}
        >
          <S.FormItem
            name="title"
            label={t('attributes.title')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <S.FormInput
              placeholder={t('categories.title')}
              onChange={(target) => (initValues.title = target.target.value)}
            />
          </S.FormItem>

          <S.FormItem
            name="type"
            label={t('variations.type')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <BaseSelect width={120} onChange={(value: any) => (initValues.type = value)}>
              <Option value="list">{t('variations.list')}</Option>
              <Option value="color">{t('variations.color')}</Option>
            </BaseSelect>
          </S.FormItem>

          <BaseCol>
            {variationData.map((item, index) => (
              <S.FormItem
                key={index}
                name="name"
                label={t('variations.value')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <S.InputsWrapper>
                  <div style={{ width: '100%' }}>
                    <S.FormInput placeholder={t('variations.value')} onChange={(event) => handleChange(index, event)} />
                  </div>
                  {index >= 1 ? (
                    <S.SubmitButton
                      style={{ width: '50%' }}
                      type="ghost"
                      danger={true}
                      htmlType="button"
                      onClick={() => RemoveItem(index)}
                    >
                      {t('variations.remove')}
                    </S.SubmitButton>
                  ) : (
                    <S.SubmitButton style={{ width: '50%' }} type="ghost" htmlType="button" onClick={() => AddItem()}>
                      {t('variations.add')}
                    </S.SubmitButton>
                  )}
                </S.InputsWrapper>
              </S.FormItem>
            ))}
          </BaseCol>

          <S.InputsWrapper style={{ marginTop: '50px' }}>
            <S.SubmitButton type="primary" htmlType="submit" loading={loading}>
              {t('attributes.submit')}
            </S.SubmitButton>
          </S.InputsWrapper>
        </BaseForm>
      </S.Card>
    </>
  );
};

export default NewVariationPage;
