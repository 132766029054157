import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '@app/pages/Settings/Countries/CountryPage.style';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { InquiryModel } from '@app/domain/Inquiry.model';
import { Versions } from '@app/api/Inquiry.api';
import { InquiryData, InquiryVersionTableColumns } from '@app/interfaces/inquiry.interface';
import { useLocation } from 'react-router';
import moment from 'moment';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const InquiryVersionsPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [versions, setVersions] = useState<InquiryData[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setVersions([]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Versions(location.state.id)
      .then((result) => {
        if (result.success) {
          result.data.map((version, index) => {
            setVersions((versions) => {
              return [
                ...versions,
                {
                  id: version.id,
                  index: version.id,
                  email: version.email,
                  user: version.user,
                  code: version.code,
                  status: version.status,
                  version: version.version,
                  totalPrice: version.totalPrice,
                  createdAt: moment(version.createdAt).format('Y-M-D h:i:s'),
                  actions: (
                    <BaseSpace>
                      <BaseButton type="ghost" onClick={() => handleOpenShow(version)}>
                        {t('common.show')}
                      </BaseButton>
                    </BaseSpace>
                  ),
                },
              ];
            });
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  }, []);

  const handleOpenShow = (inquiry: InquiryModel) => {
    navigate('/inquiries/' + inquiry.uuid, { state: inquiry });
  };

  const handleCreateVersions = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigate('/inquiries/' + location.state.uuid + '/versions/create', { state: location.state });
  };

  return (
    <>
      <PageTitle>Versions</PageTitle>
      <S.Card title={t('inquiries.versions')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <S.Card title={t('inquiries.inquiry-info')} padding="1.25rem 1.25rem 0">
            <S.InputsWrapper>
              <div style={{ width: '100%' }}>
                <p>{t('inquiries.code')}</p>
                <p>{location.state.code}</p>
              </div>

              <div style={{ width: '100%' }}>
                <p>{t('inquiries.totalPrice')}</p>
                <p>{location.state.totalPrice}</p>
              </div>

              <div style={{ width: '100%' }}>
                <p>{t('inquiries.user')}</p>
                <p>{location.state.user ? location.state.user.email : location.state.email}</p>
              </div>
            </S.InputsWrapper>
          </S.Card>
          <BaseButton type="primary" style={{ marginTop: 20, marginBottom: 20 }} onClick={handleCreateVersions}>
            {t('inquiries.new-version')}
          </BaseButton>

          <S.FormItem style={{ width: '100%' }} name="inquiries" label={t('inquiries.code')}>
            <S.FormInput
              placeholder={t('inquiries.code')}
              onChange={(target) => {
                setVersions(versions.filter((item) => item.code.includes(target.target.value)));
              }}
            />
          </S.FormItem>

          <S.Table
            scroll={{ x: 800 }}
            columns={InquiryVersionTableColumns(t)}
            dataSource={versions}
            loading={loading}
          />
        </S.TablesWrapper>
      </S.Card>
    </>
  );
};
export default InquiryVersionsPage;
