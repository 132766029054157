import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '@app/pages/Settings/Countries/CountryPage.style';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { InquiryModel } from '@app/domain/Inquiry.model';
import { Inquiries, TodayInquiries } from '@app/api/Inquiry.api';
import { TablePagination } from '@app/interfaces/table.interface';
import { InquiryData, InquiryTableColumns } from '@app/interfaces/inquiry.interface';
import moment from 'moment';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

const TodayInquiryPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [inquiries, setInquiries] = useState<InquiryData[]>([]);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<TablePagination>({ current: 1, pageSize: 10, total: 1 });
  const [openModal, setOpenModal] = useState(false);
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    setInquiries([]);
    TodayInquiries({ page: pagination.current })
      .then((result) => {
        if (result.success) {
          console.log(result.data.inquiries)
          const inquiresList = result.data.inquiries.map((inquiry, index) => ({
            id: inquiry.id,
            index: inquiry.id,
            code: inquiry.code,
            email: inquiry.email,
            status: inquiry.status,
            version: inquiry.version,
            totalPrice: inquiry.totalPrice,
            createdAt: moment(inquiry.createdAt).format('Y-M-D h:i:s'),
            user: inquiry.user ? inquiry.user.email : inquiry.email,
            actions:
              inquiry.type === 'normal' ? (
                <BaseSpace>
                  {inquiry.description ? (
                    <BaseButton type="ghost" onClick={() => handleOpenModal(inquiry)}>
                      {t('common.show')}
                    </BaseButton>
                  ) : (
                    <></>
                  )}
                  {inquiry.file ? <a href={inquiry.file}>File</a> : <></>}
                </BaseSpace>
              ) : inquiry.type === 'cart' ? (
                <BaseSpace>
                  <BaseButton type="ghost" onClick={() => handleOpenShow(inquiry)}>
                    {t('common.show')}
                  </BaseButton>
                  <BaseButton type="ghost" onClick={() => handleOpenVersions(inquiry)}>
                    {t('inquiries.versions')}
                  </BaseButton>
                  {inquiry.file ? <a href={inquiry.file}>File</a> : <></>}
                </BaseSpace>
              ) : (
                <></>
              ),
          }));
          setInquiries(inquiresList);
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  }, []);

  const handleOpenModal = (inquiry: InquiryModel) => {
    setOpenModal(true);
    setDescription(inquiry.description);
  };
  
  const cancelModal = () => {
    setOpenModal(false);
    setDescription("");
  };

  const handleOpenShow = (inquiry: InquiryModel) => {
    navigate('/inquiries/today/' + inquiry.uuid, { state: inquiry });
  };

  const handleOpenVersions = (inquiry: InquiryModel) => {
    navigate('/inquiries/today/' + inquiry.uuid + '/versions', { state: inquiry });
  };

  return (
    <>
      <PageTitle>Inquiries</PageTitle>
      <S.Card title={t('inquiries.inquiries')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <S.FormItem style={{ width: '100%' }} name="inquiries" label={t('inquiries.code')}>
            <S.FormInput
              placeholder={t('inquiries.code')}
              onChange={(target) => {
                setInquiries(inquiries.filter((item) => item.code.includes(target.target.value)));
              }}
            />
          </S.FormItem>

          <S.Table scroll={{ x: 800 }} columns={InquiryTableColumns(t)} dataSource={inquiries} loading={loading} />
        </S.TablesWrapper>
      </S.Card>
      <BaseModal
        title={t('products.updateStatus')}
        visible={openModal}
        centered
        size="large"
        onOk={() => cancelModal()}
        onCancel={() => cancelModal()}
        cancelText={t('common.close')}
        okText={t('common.ok')}
      >
        <h3>{t("products.description")}</h3>
        <p>{description}</p>
      </BaseModal>
    </>
  );
};
export default TodayInquiryPage;
