import React, { useEffect, useState } from 'react';
import * as S from '@app/pages/Products/brands/BrandPage.style';
import { useTranslation } from 'react-i18next';
import { TablePagination } from '@app/interfaces/table.interface';
import { BrandModel } from '@app/domain/Brand.model';
import { useNavigate } from 'react-router-dom';
import { BrandData, BrandTableColumns } from '@app/interfaces/brand.interface';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { Brands } from '@app/api/Brand.api';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const BrandPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<TablePagination>({ current: 1, pageSize: 10, total: 1 });
  const navigate = useNavigate();
  const [brands, setBrands] = useState<BrandData[]>([]);

  const handleOpenBrand = (brand: BrandModel) => {
    navigate('/products/brands/' + brand.uuid, { state: brand });
  };

  const getBrands = () => {
    setBrands([]);
    Brands({ page: pagination.current })
      .then((result) => {
        if (result.success) {
          result.data.brands.map((brand, index) => {
            setBrands((brands) => [
              ...brands,
              {
                index: brand.id,
                title: brand.title,
                status: brand.status,
                thumbnail: <img src={brand.thumbnail} width={50} />,
                actions: (
                  <BaseSpace>
                    <BaseButton type="ghost" onClick={() => handleOpenBrand(brand)}>
                      {t('common.update')}
                    </BaseButton>
                  </BaseSpace>
                ),
              },
            ]);
          });

          setPagination({
            current: pagination.current,
            pageSize: 10,
            total: result.data.count,
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  useEffect(() => {
    getBrands();
  }, []);

  const handleTableChange = (pagination: TablePagination) => {
    setPagination({
      current: pagination.current++,
      pageSize: pagination.pageSize,
      total: pagination.total,
    });
    getBrands();
  };

  return (
    <>
      <PageTitle>Brands</PageTitle>
      <S.Card title={t('variations.variations')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <S.Table
            scroll={{ x: 800 }}
            columns={BrandTableColumns(t)}
            dataSource={brands}
            loading={loading}
            pagination={pagination}
            onChange={() => handleTableChange(pagination)}
          />
        </S.TablesWrapper>
      </S.Card>
    </>
  );
};
export default BrandPage;
