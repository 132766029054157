import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { GetAllPages, UpdatePage } from '@app/api/Page.api';
import { PageModel } from '@app/domain/Page.model';
import * as S from './styles.style';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { PageTableColumns } from '@app/interfaces/page.interface';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export const PageList: React.FC = () => {
  const { t } = useTranslation();

  const [pages, setPages] = useState<PageModel[]>([]);
  const [loading, setLoading] = useState(true);
  const navigator = useNavigate();

  // Fetch Pages
  const fetchPages = () => {
    setLoading(true);
    GetAllPages()
      .then((response) => {
        if (response.success) {
          setPages(response.data);
        } else {
          notificationController.error({ message: response.message });
        }
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPages();
  }, []);

  // Handle Update
  const handleUpdatePage = (page: PageModel) => {
    navigator(`/pages/${page.uuid}`);
  };

  // Render Table Rows
  const renderRows = () => {
    return pages.map((page, index) => ({
      key: page.id,
      index: index + 1,
      title: page.title,
      slug: page.slug,
      actions: (
        <BaseSpace>
          <BaseButton type="ghost" onClick={() => handleUpdatePage(page)}>
            {t('common.update')}
          </BaseButton>
        </BaseSpace>
      ),
    }));
  };

  return (
    <S.TablesWrapper>
      <PageTitle>Pages</PageTitle>
      <S.Card title={t('pages.page-list')} padding="1.25rem">
        <S.Table
          columns={PageTableColumns(t)}
          dataSource={renderRows()}
          loading={loading}
        />
      </S.Card>
    </S.TablesWrapper>
  );
};
