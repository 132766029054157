export const AttributeTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('attributes.title'),
    dataIndex: 'title',
    showSorterTooltip: true,
  },
  {
    title: t('category'),
    dataIndex: 'category',
    showSorterTooltip: true,
  },
  {
    title: t('categories.actions'),
    dataIndex: 'actions',
    width: '15%',
  },
];

export interface AttributeData {
  index: number;
  title: string;
  category: string;
  actions: JSX.Element;
}

export interface AttributeRow {
  title: string;
  id?: number | undefined;
  items?: AttributeRow[];
}
