import { httpApi } from '@app/api/http.api';
import { VariationModel } from '@app/domain/Variation.model';
import { VariationRaw } from '@app/interfaces/Variation.interface';

export interface VariationsRequest {
  page: number;
}

export interface VariationResponse {
  success: boolean;
  message: string;
  code: number;
  data: {
    variations: VariationModel[];
    count: number;
  };
}

export interface VariationCreateResponse {
  success: boolean;
  message: string;
  code: number;
  data: VariationModel;
}

export const Variations = (attributePayload: VariationsRequest): Promise<VariationResponse> =>
  httpApi.get<VariationResponse>('products/variations?page=' + attributePayload.page).then(({ data }) => data);

export const StoreVariation = (name: string, type: string, inputs: VariationRaw[]): Promise<VariationCreateResponse> =>
  httpApi
    .post<VariationCreateResponse>('products/variations/create', {
      name: name,
      type: type,
      inputs: inputs,
    })
    .then(({ data }) => data);

export const DeleteVariation = (itemId: number | undefined, id: number): Promise<VariationCreateResponse> =>
  httpApi
    .get<VariationCreateResponse>('products/variations/destroy/item/' + itemId + '/' + id)
    .then(({ data }) => data);

export const UpdateVariation = (
  id: number,
  name: string,
  type: string,
  inputs: VariationRaw[],
): Promise<VariationCreateResponse> =>
  httpApi
    .post<VariationCreateResponse>('products/variations/update/' + id, {
      name: name,
      type: type,
      inputs: inputs,
    })
    .then(({ data }) => data);

export const DeleteMainVariation = (id: number): Promise<VariationCreateResponse> =>
  httpApi.get<VariationCreateResponse>('products/variations/destroy/main/' + id).then(({ data }) => data);
