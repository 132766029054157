import styled from 'styled-components';
import { Button } from 'antd';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input as CommonInput } from '@app/components/common/inputs/Input/Input';
import { Select as CommonSelect } from '@app/components/common/selects/Select/Select';
import { BaseSteps } from '@app/components/common/BaseSteps/BaseSteps';

export const ProductWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1.5rem;
`;

export const Loading = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
  text-align: center;
  padding: 3rem;
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  background-color: var(--background-color);

  ul,
  li {
    list-style: none;
  }
`;

export const Form = styled(BaseForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.25rem;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: ${FONT_SIZE.md};
    font-weight: ${FONT_WEIGHT.semibold};
    color: var(--primary-color);
  }

  input,
  textarea,
  select {
    font-size: ${FONT_SIZE.md};
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    background: transparent;
  }

  input[type='file'] {
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  select {
    option {
      padding: 0.5rem;
    }
  }

  ${media.md} {
    width: 100%;
  }
`;

export const FormItem = styled(BaseForm.Item)`
  width: 100%;
  min-width: 200px;
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.md};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  textarea,
  input,
  .ant-select-selector {
    background: transparent !important;
  }

  .quill {
    .ql-toolbar {
      border-radius: .25rem .25rem 0 0;
    }
    
    .ql-container {
      border-radius: 0 0 .25rem .25rem;
      min-height: 250px;
    }
  }

  .img-show {
    display: inline-block;
    margin: 10px 10px 10px 0;
    position: relative;

    img {
      width: 200px;
      height: 200px;
    }

    button {
      font-size: ${FONT_SIZE.xxxxl};
      line-height: ${FONT_SIZE.xxxxl};
      width: 2.5rem;
      height: 2.5rem;
      background: transparent;
      border: 1px solid red;
      border-radius: 5px;
      color: red;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    div {
      &.stroke {
        svg {
          stroke: var(--text-main-color);
        }
      }

      &.fill {
        svg {
          fill: var(--text-main-color);
        }
      }
    }
  }

  .price {
    margin-left: 20px;
  }

  .files-wrapper {
    display: flex;
    gap: 5%;
    flex-wrap: wrap;

    .file {
      width: 100%;
    }

    @media only screen and ${media.md} {
      gap: 5%;

      .file {
        width: 47.5%;
      }
    }
    
    @media only screen and ${media.lg} {
      gap: 3.33%;

      .file {
        width: 30%;
      }
    }
  }
`;

export const FormInput = styled(CommonInput)`
  width: 100%;
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }

  ::file-selector-button {
    background: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    outline: none;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      color: var(--secondary-color);

      border-color: var(--secondary-color);
    }
  }
`;

export const FileInput = styled.input`
  width: 100%;
  color: var(--text-main-color);
  background: transparent;
  min-width: 5.5rem;
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.lg};
  border: 1px solid var(--border-color);
  outline: none !important;
  padding: 8px;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    border: 1px solid var(--primary-color);
  }

  &:focus {
    outline: none !important;
    border: 1px solid var(--primary-color);
  }

  &::file-selector-button {
    background: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    outline: none;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }

  &:disabled {
    background-color: var(--disabled-bg-color);
    color: var(--disabled-text-color);
    border-color: var(--disabled-border-color);
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:disabled::file-selector-button {
    background-color: var(--disabled-bg-color);
    color: var(--disabled-text-color);
    border-color: var(--disabled-border-color);
    cursor: not-allowed;
  }
`;


export const Select = styled(CommonSelect)`
  width: 100%;
  .ant-select-selector {
    background: transparent !important;
    border-radius: 0.25rem;
    font-size: ${FONT_SIZE.md};
    padding: 0.5rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const UpdateButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  padding: 0.75rem 2rem;
  background-color: var(--primary-color);
  color: var(--text-light-color);
  border: none;
  border-radius: 0.25rem;

  &:hover {
    background-color: var(--primary-dark-color);
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
`;

export const DetailsTitle = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--text-light-color);
`;

export const DetailsValue = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--text-main-color);
`;

export const PriceInputWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;

  input {
    width: 48%;
    margin-bottom: 1rem;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  input {
    width: 48%;
  }
`;

export const StatusSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25rem;
`;

export const CategorySelectWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25rem;
`;

export const FileInputWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;

  input[type='file'] {
    margin: 0.5rem 0;
  }
`;

export const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  background: transparent;
  color: #fff !important;
  padding: 4px 8px;
  margin: 0 8px 8px 0;
  border: 1px solid #fff;
  backdrop-filter: blur(2px);
  border-radius: 4px;
  font-size: 14px;

  .close-icon {
    cursor: pointer;
    margin-left: 8px;
    color: #999;
    font-weight: bold;
  }
`;

export const Note = styled.h3`
  color: var(--error-color);
`

export const Steps = styled(BaseSteps)``;
