import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './BrandPage.style';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { InboxOutlined } from '@ant-design/icons';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { StoreBrand } from '@app/api/Brand.api';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export const initValues = {
  title: '',
  thumbnail: undefined,
  description: '',
  status: '',
};

const NewBrandPage: React.FC = () => {
  const { t } = useTranslation();
  const [thumbnailFile, setThumbnailFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const uploadProps = {
    name: 'thumbnail',
    multiple: false,
    accept: '*.png',
    onChange: (info: any) => {
      setThumbnailFile(info.file.originFileObj);
    },
  };

  const handleSubmitCreate = (values: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('status', values.status);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append('thumbnail', thumbnailFile);
    StoreBrand(formData)
      .then((result) => {
        if (result.success) {
          notificationController.success({ message: result.message });
          navigate('/products/brands');
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  return (
    <>
      <PageTitle>Brands</PageTitle>
      <S.Card title={t('brands.new-brand')} padding="1.25rem 1.25rem 0">
        <BaseForm
          style={{ width: '70%', margin: '0 auto 0 auto' }}
          layout="vertical"
          onFinish={handleSubmitCreate}
          requiredMark="optional"
          initialValues={initValues}
        >
          <S.FormItem
            name="title"
            label={t('brands.title')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <S.FormInput
              placeholder={t('brands.title')}
              onChange={(target) => (initValues.title = target.target.value)}
            />
          </S.FormItem>

          <S.FormItem
            name="status"
            label={t('brands.status')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <BaseSelect width={120} onChange={(value: any) => (initValues.status = value)}>
              <Option value="active">{t('common.active')}</Option>
              <Option value="deactivate">{t('common.deactivate')}</Option>
            </BaseSelect>
          </S.FormItem>

          <S.FormItem
            name="description"
            label={t('brands.description')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <S.InputsWrapper>
              <BaseInput.TextArea rows={4} />
            </S.InputsWrapper>
          </S.FormItem>

          <S.FormItem
            name="thumbnail"
            rules={[{ required: true, message: t('common.requiredField') }]}
            label={t('brands.thumbnail')}
          >
            <BaseUpload.Dragger {...uploadProps}>
              <S.DraggerIconWrapper>
                <InboxOutlined />
              </S.DraggerIconWrapper>
              <S.DraggerTitle>{t('uploads.dragUpload')}</S.DraggerTitle>
            </BaseUpload.Dragger>
          </S.FormItem>

          <S.InputsWrapper style={{ marginTop: '50px' }}>
            <S.SubmitButton type="primary" htmlType="submit" loading={loading}>
              {t('attributes.submit')}
            </S.SubmitButton>
          </S.InputsWrapper>
        </BaseForm>
      </S.Card>
    </>
  );
};

export default NewBrandPage;
