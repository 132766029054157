import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import * as serviceWorker from './serviceWorker';
import 'config/config';
import { Provider } from 'react-redux';
import { store } from '@app/store/store';
import ReactDOM from 'react-dom/client';
import React from 'react';

interface EventTarget {
  state?: 'activated';
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

serviceWorker.unregister();
