import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '@app/pages/Settings/Countries/States/StatePage.style';
import { StateData, StateInterface, StateRequest, StateTableColumns } from '@app/interfaces/country.interface';
import { Cities, States, StoreCity, StoreCountry, StoreState, UpdateCity, UpdateState } from '@app/api/Country.api';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { StateModal } from '@app/domain/Country.model';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export let initValues: StateInterface = {
  id: 0,
  title: '',
  code: '',
  status: '',
  latitude: '',
  longitude: '',
};

const cityPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [states, setStates] = useState<StateData[]>([]);
  const navigate = useNavigate();
  const history = useLocation();
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [location, setLocation] = useState<{ lat: number; lng: number }>({ lat: 0.0, lng: 0.0 });

  const getCities = () => {
    setStates([]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Cities(history.state.id)
      .then((result) => {
        if (result.success) {
          result.data.map((state, index) => {
            setStates((states) => [
              ...states,
              {
                index: state.id,
                title: state.title,
                status: state.status,
                actions: (
                  <BaseSpace>
                    <BaseButton type="ghost" onClick={() => handleOpenUpdate(state)}>
                      {t('common.update')}
                    </BaseButton>
                  </BaseSpace>
                ),
              },
            ]);
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  useEffect(() => {
    getCities();
  }, []);

  const handleOpenUpdate = (state: StateModal) => {
    initValues = {
      id: state.id,
      title: state.title,
      code: state.code,
      status: state.status,
      latitude: state.latitude,
      longitude: state.longitude,
    };
    setUpdateModal(true);
  };

  const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey: '' });

  if (loadError) {
    return <>{loadError.message}</>;
  }

  const handleSetLocation = (event: any) => {
    setLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };

  const handleSubmitCreate = () => {
    const stateRequest: StateRequest = {
      title: initValues.title,
      code: initValues.title,
      status: initValues.status,
      latitude: location.lat.toString(),
      longitude: location.lng.toString(),
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    StoreCity(history.state.id, stateRequest)
      .then((result) => {
        if (result.success) {
          setCreateModal(false);
          notificationController.success({ message: result.message });
          getCities();
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  const handleSubmitUpdate = () => {
    const stateRequest: StateRequest = {
      title: initValues.title,
      code: initValues.title,
      status: initValues.status,
      latitude: location.lat.toString(),
      longitude: location.lng.toString(),
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    UpdateCity(initValues.id, stateRequest)
      .then((result) => {
        if (result.success) {
          setUpdateModal(false);
          notificationController.success({ message: result.message });
          getCities();
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  return (
    <>
      <PageTitle>Cities</PageTitle>
      <S.Card title={t('countries.cities')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <BaseButton
            type="primary"
            onClick={() => {
              setCreateModal(true);
              initValues = {
                title: '',
                code: '',
                status: 'active',
                latitude: '',
                longitude: '',
              };
            }}
          >
            {t('countries.new-city')}
          </BaseButton>
          <br />

          <S.FormItem style={{ width: '100%' }} name="title" label={t('countries.name')}>
            <S.FormInput
              placeholder={t('countries.name')}
              onChange={(target) => {
                setStates(states.filter((item) => item.title.includes(target.target.value)));
              }}
            />
          </S.FormItem>

          <S.Table scroll={{ x: 800 }} columns={StateTableColumns(t)} dataSource={states} loading={loading} />
        </S.TablesWrapper>
      </S.Card>

      <BaseModal
        title={t('countries.new-city')}
        visible={createModal}
        centered
        size="large"
        onOk={handleSubmitCreate}
        onCancel={() => setCreateModal(false)}
        cancelText={t('common.close')}
        okText={t('common.create')}
      >
        <BaseForm
          style={{ width: '100%', margin: '0 auto 0 auto' }}
          layout="vertical"
          onFinish={handleSubmitCreate}
          requiredMark="optional"
          initialValues={initValues}
        >
          <S.InputsWrapper>
            <S.FormItem
              style={{ width: '100%' }}
              name="title"
              label={t('countries.title')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.FormInput
                placeholder={t('countries.title')}
                onChange={(target) => (initValues.title = target.target.value)}
              />
            </S.FormItem>
            <S.FormItem
              style={{ width: '100%' }}
              name="status"
              label={t('countries.status')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <BaseSelect width={120} onChange={(value: any) => (initValues.status = value)}>
                <Option value="active">{t('common.active')}</Option>
                <Option value="deactivate">{t('common.deactivate')}</Option>
              </BaseSelect>
            </S.FormItem>
          </S.InputsWrapper>
          <S.MapsCard title={t('countries.location')}>
            {isLoaded ? (
              <GoogleMap
                center={{
                  lat: location.lat,
                  lng: location.lng,
                }}
                zoom={14}
                mapContainerStyle={{ height: '100%', width: '100%' }}
                onClick={handleSetLocation}
              >
                <Marker position={{ lat: location.lat, lng: location.lng }} />
              </GoogleMap>
            ) : (
              <></>
            )}
          </S.MapsCard>
        </BaseForm>
      </BaseModal>

      <BaseModal
        title={t('countries.update-city')}
        visible={updateModal}
        centered
        size="large"
        onOk={handleSubmitUpdate}
        onCancel={() => setUpdateModal(false)}
        cancelText={t('common.close')}
        okText={t('common.update')}
      >
        <BaseForm
          style={{ width: '100%', margin: '0 auto 0 auto' }}
          layout="vertical"
          onFinish={handleSubmitUpdate}
          requiredMark="optional"
          initialValues={initValues}
        >
          <S.InputsWrapper>
            <S.FormItem
              style={{ width: '100%' }}
              name="title"
              label={t('countries.title')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.FormInput
                placeholder={t('countries.title')}
                onChange={(target) => (initValues.title = target.target.value)}
              />
            </S.FormItem>
            <S.FormItem
              style={{ width: '100%' }}
              name="status"
              label={t('countries.status')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <BaseSelect width={120} onChange={(value: any) => (initValues.status = value)}>
                <Option value="active">{t('common.active')}</Option>
                <Option value="deactivate">{t('common.deactivate')}</Option>
              </BaseSelect>
            </S.FormItem>
          </S.InputsWrapper>
          <S.MapsCard title={t('countries.location')}>
            {isLoaded ? (
              <GoogleMap
                center={{
                  lat: location.lat,
                  lng: location.lng,
                }}
                zoom={14}
                mapContainerStyle={{ height: '100%', width: '100%' }}
                onClick={handleSetLocation}
              >
                <Marker position={{ lat: location.lat, lng: location.lng }} />
              </GoogleMap>
            ) : (
              <></>
            )}
          </S.MapsCard>
        </BaseForm>
      </BaseModal>
    </>
  );
};
export default cityPage;
