import React, { useEffect, useState } from 'react';
import { GetPage, UpdatePage } from '@app/api/Page.api';
import { notificationController } from '@app/controllers/notificationController';
import { PageForm } from './PageForm';
import { PageModel } from '@app/domain/Page.model';
import * as S from './styles.style';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export const PageUpdate: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [formData, setFormData] = useState<PageModel>({
    id: 0,
    uuid: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    title: '',
    content: '',
    slug: '',
  });
  const navigator = useNavigate();

  useEffect(() => {
    const fetchPage = async () => {
      try {
        if (!uuid) {
          notificationController.error({ message: 'Page ID is missing!' });
          return;
        }
        const response = await GetPage(uuid);
        if (response.success) {
          setFormData(response.data);
        } else {
          notificationController.error({ message: `Error fetching page: ${response.message}` });
        }
      } catch (error) {
        console.error('Error fetching page:', error);
        notificationController.error({ message: 'Failed to fetch page.' });
      }
    };

    fetchPage();
  }, [uuid]);

  const updatePage = async (formData: PageModel) => {
    try {
      const response = await UpdatePage(formData.id, formData);
      if (response.success) {
        notificationController.success({ message: 'Page updated successfully!' });
        navigator('/pages');
      } else {
        notificationController.error({ message: `Error updating page: ${response.message}` });
      }
    } catch (error) {
      console.error('Error updating page:', error);
      notificationController.error({ message: 'Failed to update page.' });
    }
  };

  if (!formData.title) {
    return (
      <S.StyledSpinner>
        <Spin size="large" />
        Loading...
      </S.StyledSpinner>
    );
  }

  return (
    <S.PageWrapper>
      <PageTitle>Pages</PageTitle>
      <PageForm
        formData={formData}
        setFormData={setFormData}
        updateProduct={updatePage} // Reusing updateProduct prop
      />
    </S.PageWrapper>
  );
};
