import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '@app/pages/Settings/Countries/CountryPage.style';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { InquiryModel } from '@app/domain/Inquiry.model';
import { SearchInquiry } from '@app/api/Inquiry.api';
import { TablePagination } from '@app/interfaces/table.interface';
import { InquiryData, InquiryTableColumns } from '@app/interfaces/inquiry.interface';
import moment from 'moment/moment';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export const initValues = {
  code: '',
  fromDate: '',
  toDate: '',
  email: '',
};

const SearchInquiryPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [inquiries, setInquiries] = useState<any[]>([]);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<TablePagination>({ current: 1, pageSize: 10, total: 1 });

  const handleOpenShow = (inquiry: InquiryModel) => {
    console.log(inquiry);
    navigate('/inquiries/' + inquiry.uuid, { state: inquiry });
  };

  const handleOpenVersions = (inquiry: InquiryModel) => {
    navigate('/inquiries/' + inquiry.uuid + '/versions', { state: inquiry });
  };

  const handleSubmitSearch = (values: any) => {
    setInquiries([]);
    SearchInquiry({
      email: values.email,
      code: values.code,
      fromDate: values.fromDate,
      toDate: values.toDate,
    })
      .then((result) => {
        if (result.success) {
          result.data.map((inquiry, index) => {
            setInquiries((inquiries) => {
              return [
                ...inquiries,
                {
                  id: inquiry.id,
                  index: inquiry.id,
                  code: inquiry.code,
                  status: inquiry.status,
                  version: inquiry.version,
                  createdAt: moment(inquiry.createdAt).format('Y-M-D h:i:s'),
                  totalPrice: inquiry.totalPrice,
                  user: inquiry.user ? inquiry.user.email : inquiry.email,
                  actions: (
                    <BaseSpace>
                      <BaseButton type="ghost" onClick={() => handleOpenShow(inquiry)}>
                        {t('common.show')}
                      </BaseButton>
                      <BaseButton type="ghost" onClick={() => handleOpenVersions(inquiry)}>
                        {t('inquiries.versions')}
                      </BaseButton>
                    </BaseSpace>
                  ),
                },
              ];
            });
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  return (
    <>
      <PageTitle>Inquiries</PageTitle>
      <S.Card title={t('inquiries.search')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <BaseForm
            style={{ width: '100%', margin: '0 auto 0 auto' }}
            layout="vertical"
            onFinish={handleSubmitSearch}
            requiredMark="optional"
            initialValues={initValues}
          >
            <S.InputsWrapper>
              <S.FormItem
                style={{ width: '100%' }}
                name="code"
                label={t('inquiries.code')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <S.FormInput
                  placeholder={t('inquiries.code')}
                  onChange={(target) => (initValues.code = target.target.value)}
                />
              </S.FormItem>
              <S.FormItem
                style={{ width: '100%' }}
                name="email"
                label={t('inquiries.email')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <S.FormInput
                  placeholder={t('inquiries.email')}
                  onChange={(target) => (initValues.email = target.target.value)}
                />
              </S.FormItem>
            </S.InputsWrapper>
            <S.InputsWrapper>
              <S.FormItem
                style={{ width: '100%' }}
                name="fromDate"
                label={t('inquiries.fromDate')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <DayjsDatePicker
                  format="L"
                  style={{ width: '100%' }}
                  onChange={(target: any) => console.log(target.date)}
                />
              </S.FormItem>
              <S.FormItem
                style={{ width: '100%' }}
                name="toDate"
                label={t('inquiries.toDate')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <DayjsDatePicker
                  format="L"
                  style={{ width: '100%' }}
                  onChange={(target: any) => console.log(target.date)}
                />
              </S.FormItem>
            </S.InputsWrapper>

            <S.InputsWrapper style={{ marginBottom: '50px' }}>
              <S.SubmitButton type="primary" htmlType="submit" loading={loading}>
                {t('common.search')}
              </S.SubmitButton>
            </S.InputsWrapper>
          </BaseForm>

          <S.Table scroll={{ x: 800 }} columns={InquiryTableColumns(t)} dataSource={inquiries} loading={loading} />
        </S.TablesWrapper>
      </S.Card>
    </>
  );
};
export default SearchInquiryPage;
