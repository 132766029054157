
export const PageTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
  },
  {
    title: t('products.title'),
    dataIndex: 'title',
  },
  {
    title: t('products.slug'),
    dataIndex: 'slug',
  },
  {
    title: t('products.actions'),
    dataIndex: 'actions',
    width: '15%',
    render: (_: any, record: any) => record.actions,
  },
];

export interface PageData {
  index: number;
  title: string;
  slug: string;
  actions: JSX.Element;
}
