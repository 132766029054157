import React, { useCallback, useEffect, useState } from 'react';
import { CreateProduct as CreateProductAPI } from '@app/api/Product.api';
import { notificationController } from '@app/controllers/notificationController';
import { Categories } from '@app/api/Category.api';
import { Brands } from '@app/api/Brand.api';
import ProductWizardForm from './ProductWizardForm';
import {
  ProductFormData,
  CategoryShowModel,
  Tags,
  BrandShowModel,
  Prices,
  VideoGallery,
  CategoryOptionsModel,
  Attribute,
} from '@app/constants/productDetails';
import { CategoryModel } from '@app/domain/CategoryModel';
import { BrandData } from '@app/interfaces/brand.interface';
import * as S from './SingleProductPage.style';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const NewProductPage: React.FC = () => {
  const [formData, setFormData] = useState<ProductFormData>({
    title: '',
    description: '',
    shortDescription: '',
    sku: '',
    categories: [],
    tags: [],
    attributes: [],
    prices: [],
  });

  const [categoryOptions, setCategoryOptions] = useState<CategoryOptionsModel[]>([]);
  const [brands, setBrands] = useState<BrandData[]>([]);

  // Map categories into a tree structure for the form.
  const mapToTreeData = (categories: CategoryModel[]): CategoryOptionsModel[] => {
    return categories.map((category) => ({
      label: category.title,
      value: category.id,
      key: category.id,
      children: category.categories && category.categories.length > 0 ? mapToTreeData(category.categories) : [],
    }));
  };

  const fetchCategories = useCallback(() => {
    Categories({ type: 'product', page: 1 })
      .then((response) => {
        if (response.success) {
          const treeData = mapToTreeData(response.data.allCategories);
          setCategoryOptions(treeData);
        } else {
          notificationController.error({ message: response.message });
        }
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const getBrands = useCallback(() => {
    setBrands([]);
    Brands()
      .then((result) => {
        if (result.success) {
          setBrands(
            result.data.brands.map((brand) => ({
              index: brand.id,
              title: brand.title,
            })),
          );
        }
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });
  }, []);

  useEffect(() => {
    getBrands();
  }, [getBrands]);

  function isBrandShowModel(obj: any): obj is BrandShowModel {
    return (
      obj &&
      typeof obj === 'object' &&
      'label' in obj &&
      'value' in obj &&
      typeof obj.label === 'string' &&
      typeof obj.value === 'number'
    );
  }

  const createProduct = async (formData: ProductFormData) => {
    const data = new FormData();

    for (const [key, value] of Object.entries(formData)) {
      if (Array.isArray(value)) {
        if (key === 'prices') {
          (value as Prices[]).forEach((item: Prices, index: number) => {
            if (item.price) {
              data.append(`prices[${index}][price]`, item.price.toString());
            }
            if (item.stock !== undefined) {
              data.append(`prices[${index}][stock]`, item.stock.toString());
            }
            data.append(`prices[${index}][min]`, '0');
            data.append(`prices[${index}][max]`, '0');
          });
        } else if (key === 'categories') {
          (value as CategoryShowModel[]).forEach((category, index) => {
            data.append(`categories[${index}][label]`, category.label);
            data.append(`categories[${index}][value]`, category.value.toString());
          });
        } else if (key === 'tags') {
          (value as Tags[]).forEach((tag, index) => {
            data.append(`tags[${index}][name]`, tag.name);
            data.append(`tags[${index}][id]`, tag.id.toString());
          });
        } else if(key === 'attributes') {
          (value as Attribute[]).forEach((attribute, index) => {
            data.append(`attributes[${index}][id]`, attribute.id?.toString() || '0');
            data.append(`attributes[${index}][title]`, attribute.title || '');
            data.append(`attributes[${index}][value]`, attribute.value || '');
            data.append(`attributes[${index}][valueId]`, attribute.valueId?.toString() || '0');
            attribute.items.forEach((_attribute, _index) => {
              data.append(`attributes[${index}][items][${_index}][id]`, _attribute.id?.toString() || '0');
              data.append(`attributes[${index}][items][${_index}][title]`, _attribute.title || '');
              data.append(`attributes[${index}][items][${_index}][value]`, _attribute.value || '');
              data.append(`attributes[${index}][items][${_index}][valueId]`, _attribute.valueId?.toString() || '0');
              _attribute.items.forEach((attr, i) => {
                data.append(`attributes[${index}][items][${_index}][items][${i}][id]`, attr.id?.toString() || '0');
                data.append(`attributes[${index}][items][${_index}][items][${i}][title]`, attr.title || '');
                data.append(`attributes[${index}][items][${_index}][items][${i}][value]`, attr.value || '');
                data.append(`attributes[${index}][items][${_index}][items][${i}][valueId]`, attr.valueId?.toString() || '0');
              })
            });
          });
        } else if (key === 'imageGallery') {
          (value as File[]).forEach((file: File, index: number) => {
            data.append(key, file);
          });
        } else if (key === 'fileGallery') {
          (value as VideoGallery[]).forEach((file: VideoGallery, index: number) => {
            data.append(`titleFileGallery[${index}]`, file.title);
            data.append(key, file.file as File);
          });
        } else if (key === 'videoGallery') {
          (value as VideoGallery[]).forEach((video: VideoGallery, index: number) => {
            data.append(`titleVideoGallery[${index}]`, video.title);
            data.append(key, video.file as File);
          });
        } else {
          (value as (string | File)[]).forEach((item) => data.append(key, item));
        }
      } else if (key === 'thumbnail' || key === 'imageGallery' || key === 'fileGallery') {
        console.log('key', key);
        data.append(key, value as string);
      } else if (isBrandShowModel(value)) {
        if (key === 'brand') {
          data.append(`brand[label]`, (value as BrandShowModel).label);
          data.append(`brand[value]`, (value as BrandShowModel).value.toString());
        }
      } else {
        if (value !== undefined && value !== null) {
          data.append(key, value.toString());
        }
      }
    }

    
    try {
      const response = await CreateProductAPI(data);
      if (response.success) {
        notificationController.success({ message: 'Product created successfully!' });
        setFormData({
          title: '',
          description: '',
          shortDescription: '',
          sku: '',
          categories: [],
          tags: [],
          attributes: [],
          prices: [],
        });
        location.reload();
      } else {
        notificationController.error({ message: `Error creating product: ${response.message}` });
      }
    } catch (error) {
      console.error('Error creating product:', error);
      notificationController.error({ message: 'Failed to create product.' });
    }
  };

  return (
    <S.ProductWrapper>
      <PageTitle>Products</PageTitle>
      <ProductWizardForm
        formData={formData}
        setFormData={setFormData}
        updateProduct={createProduct}
        categoryOptions={categoryOptions}
        brands={brands}
        isUpdating={false}
      />
    </S.ProductWrapper>
  );
};

export default NewProductPage;
