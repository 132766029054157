import styled from 'styled-components';
import { Card as CommonCard } from '@app/components/common/Card/Card';
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input as CommonInput } from '@app/components/common/inputs/Input/Input';
import { Button, Radio, Table as AntdTable } from 'antd';
import { Select as CommonSelect } from '@app/components/common/selects/Select/Select';
// import { Option as CommonOption } from '@app/components/common/selects/Option/Option';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseSteps } from '@app/components/common/BaseSteps/BaseSteps';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

export const Card = styled(CommonCard)`
  margin-bottom: 1.25rem;
  .ant-card-head-title {
    font-size: 1rem;
  }
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    align-items: center;
  }
  .ant-card-body:before {
    display: none;
  }
  .ant-card-body:after {
    display: none;
  }
  &.ant-card-bordered {
    border: 1px solid var(--border-color);
  }
`;

export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
`;

export const SubmitButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
  height: 100%;
`;

export const FormItem = styled(BaseForm.Item)`
  min-width: 200px;
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`;

export const FormInput = styled(CommonInput)`
  width: 100%;
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
`;

export const TablesWrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Table = styled(AntdTable)`
  width: 100%;
  & thead .ant-table-cell {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;

    & .anticon {
      color: var(--primary-color);
    }
  }

  & tbody .ant-table-cell {
    color: var(--text-main-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  & tbody .ant-table-row-expand-icon {
    min-height: 1.25rem;
    min-width: 1.25rem;
    border-radius: 0.1875rem;
    margin-top: 0;
  }

  // Override default antd selector
  &
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: var(--primary-color);
  }

  & .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    min-width: 2.0625rem;
    height: 2.0625rem;
    line-height: 2.0625rem;
    border-radius: 0;
    font-size: ${FONT_SIZE.xs};
  }

  & .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0;
  }

  & .ant-checkbox-inner {
    border-radius: 0.1875rem;
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid var(--primary-color);
  }

  & .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 0.75rem;
  }

  .ant-table-column-sort {
    background-color: transparent;
  }

  .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: var(--disabled-color);
  }

  .ant-pagination-disabled {
    .ant-pagination-item-link,
    .ant-pagination-item a {
      color: var(--disabled-color);
    }
  }

  .ant-pagination.ant-pagination-disabled {
    .ant-pagination-item-link,
    .ant-pagination-item a {
      color: var(--disabled-color);
    }
  }
`;

export const DraggerIconWrapper = styled.div`
  font-size: 4rem;
  color: var(--primary-color);
`;

export const DraggerTitle = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.bold};
`;

export const DraggerDescription = styled.div`
  font-size: ${FONT_SIZE.md};
  padding: 0 1rem;
`;

export const MapsCard = styled(BaseCard)`
  height: 70vh;
  overflow-y: auto;

  .leaflet-container {
    z-index: 0;
  }
`;

export const PhoneItem = styled(BaseForm.Item)`
  .ant-input-group-addon {
    padding: 0;
  }

  .ant-input-group-addon > div {
    width: 100% !important;
  }
`;

export const Steps = styled(BaseSteps)``;

export const FormContent = styled.div`
  margin: 1.25rem 0.5rem;
`;

export const PrevButton = styled(BaseButton)`
  margin: 0 0.5rem;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin: 1.25rem 0.5rem;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailsTitle = styled.div`
  color: var(--text-light-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  margin-right: 0.5rem;
`;

export const DetailsValue = styled.div`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const Row = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Form = styled(BaseForm)`
  width: 100%;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .ant-form-item-label {
    font-size: ${FONT_SIZE};
    color: var(--primary-color);
  }
`;

export const RadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  gap: 1rem;
`;

export const RadioButton = styled(Radio.Button)`
  background: var(--background-color);
  color: var(--text-main-color);
  border: 1px solid var(--primary-color);
  padding: 0.5rem 1rem;
  font-size: ${FONT_SIZE.md};
  border-radius: 0.25rem;

  &.ant-radio-button-wrapper-checked {
    background: var(--primary-color);
    color: var(--text-light-color);
  }
`;

export const SearchForm = styled(BaseForm)`
  // width: 1920px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1.25rem;
  background-color: var(--background-color);
  border-radius: 0.5rem;
`;

export const Select = styled(CommonSelect)`
  width: 100%;
  .ant-select-selector {
    border-radius: 0.25rem;
    font-size: ${FONT_SIZE.md};
    padding: 0.5rem;
  }
`;

export const SearchButton = styled(BaseButton)`
  margin-bottom: 0.75rem;
`;
