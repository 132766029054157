import React, { useState, useEffect, useRef } from 'react';
import { ProductFormData, VideoGallery } from '@app/constants/productDetails';
import { useTranslation } from 'react-i18next';
import * as S from '../SingleProductPage.style';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { ProductModel } from '@app/domain/Product.model';
import { FileIcon } from '@app/components/common/icons/FileIcon';
import { RemoveFile, RemoveImage, RemoveVideo } from '@app/api/Product.api';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { notificationController } from '@app/controllers/notificationController';

interface FilePreview {
  title: string;
  src: string;
  id?: number;
  notUploadable?: boolean;
}

interface Props {
  formData: ProductFormData;
  setFormData: React.Dispatch<React.SetStateAction<ProductFormData>>;
  product?: ProductModel;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>, field: string) => void;
  onVideoChange: (videos: VideoGallery[]) => void;
  onFileChange: (files: VideoGallery[]) => void;
  setProductAgain?: () => void;
}

const MediaForm = ({ formData, handleFileChange, product, onVideoChange, onFileChange, setFormData, setProductAgain }: Props) => {
  const { t } = useTranslation();

  const [thumbnailPreview, setThumbnailPreview] = useState<string | null>(null);
  const [imageGalleryPreviews, setImageGalleryPreviews] = useState<{ image: string; id?: number }[]>([]);
  const [fileGalleryPreviews, setFileGalleryPreviews] = useState<FilePreview[]>([]);
  const [videoGalleryPreviews, setVideoGalleryPreviews] = useState<FilePreview[]>([]);
  const [videoGallery, setVideoGallery] = useState<VideoGallery[]>([]);
  const [fileGallery, setFileGallery] = useState<VideoGallery[]>([]);
  const [destroyModal, setDestroyModal] = useState(false);

  const thumbnailRef = useRef<HTMLInputElement | null>(null);
  const imageGalleryRef = useRef<HTMLInputElement | null>(null);
  const fileGalleryRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (formData.thumbnail) {
      setThumbnailPreview(URL.createObjectURL(formData.thumbnail[0]));
    } else if (product?.thumbnail) {
      setThumbnailPreview(product?.thumbnail);
    }
  }, [formData.thumbnail, product?.thumbnail]);

  useEffect(() => {
    const imagePreviews: { image: string; id?: number }[] = [];

    if (product?.imageGalleries) {
      imagePreviews.push(...product.imageGalleries.map((img) => img));
    }

    if (formData.imageGallery?.length) {
      imagePreviews.push(
        ...formData.imageGallery.map((file) =>
          file instanceof File ? { image: URL.createObjectURL(file) } : { image: '' },
        ),
      );
    }

    setImageGalleryPreviews(imagePreviews);
  }, [formData.imageGallery, product?.imageGalleries]);

  useEffect(() => {
    const filePreviews: FilePreview[] = [];

    if (product?.fileGalleries) {
      filePreviews.push(
        ...product?.fileGalleries.map((file) => {
          return { title: file.title, src: file.file, id: file.id, notUploadable: true };
        }),
      );
    }
    if (formData.fileGallery) {
      filePreviews.push(
        ...formData.fileGallery.map((file) => {
          return { title: file.title, src: file.file instanceof File ? URL.createObjectURL(file.file) : '' };
        }),
      );
    }

    setFileGalleryPreviews(filePreviews);
  }, [product?.fileGalleries, formData.fileGallery]);

  useEffect(() => {
    const videoPreviews: FilePreview[] = [];

    if (product?.videoGalleries) {
      videoPreviews.push(
        ...product?.videoGalleries.map((file) => {
          return { title: file.title, src: file.video, id: file.id, notUploadable: true };
        }),
      );
    }
    if (formData.videoGallery) {
      videoPreviews.push(
        ...formData.videoGallery.map((file) => {
          return { title: file.title, src: file.file instanceof File ? URL.createObjectURL(file.file) : '' };
        }),
      );
    }

    setVideoGalleryPreviews(videoPreviews);
  }, [product?.videoGalleries, formData.videoGallery]);

  const handleClearImage = async (index: number, field: 'thumbnail' | 'imageGallery', id?: number) => {
    if (field === 'thumbnail') {
      // Handle thumbnail separately
      setFormData((prev) => ({
        ...prev,
        thumbnail: undefined,
      }));
      setThumbnailPreview(null);
      if (thumbnailRef.current) {
        thumbnailRef.current.value = '';
      }
    } else if (field === 'imageGallery') {
      // Check if the image comes from the product
      const isFromProduct = product?.imageGalleries && product.imageGalleries[index];

      if (isFromProduct && id) {
        try {
          const response = await RemoveImage(product.id, id);
          if (response.success) {
            notificationController.success({ message: response.message });
  
            setImageGalleryPreviews((prev) => prev.filter((_, i) => i !== index));
            setProductAgain && setProductAgain();
          } else {
            notificationController.error({ message: `Error removing image: ${response.message}` });
          }
        } catch (error: any) {
          console.error('Failed to delete image:', error);
          notificationController.error({ message: error.message });
        }
      } else if (isFromProduct && !id) {
        console.log('Id was not found');
      } else {
        const formDataIndex = index - (product?.fileGalleries?.length ?? 0);
        // The image is from formData, remove it from formData and previews
        const galleryField = formData[field] as File[];
        if (galleryField) {
          const dataTransfer = new DataTransfer();
          galleryField.forEach((file, i) => {
            if (i !== formDataIndex) {
              dataTransfer.items.add(file as File);
            }
          });

          setFormData((prev) => ({
            ...prev,
            [field]: Array.from(dataTransfer.files),
          }));

          setImageGalleryPreviews((prev) => prev.filter((_, i) => i !== index));

          if (imageGalleryRef.current) {
            imageGalleryRef.current.files = dataTransfer.files;
          }
        }
      }
    }
  };

  const handleAddVideo = () => {
    const updatedVideos = [...videoGallery, { title: '', file: null }];
    setVideoGallery(updatedVideos);
    onVideoChange(updatedVideos);
  };

  const handleVideoChange = (index: number, key: 'title' | 'file', value: string | File | null) => {
    const formDataIndex = index - (product?.fileGalleries?.length ?? 0);

    const updatedVideos = videoGallery.map((item, i) => (i === formDataIndex ? { ...item, [key]: value } : item));
    setVideoGallery(updatedVideos);
    onVideoChange(updatedVideos);
  };

  const handleClearVideo = async (index: number, id?: number) => {
    const isFromProduct = product?.videoGalleries && product.videoGalleries[index];

    if (isFromProduct && id) {
      try {
        const response = await RemoveVideo(product.id, id);
        if (response.success) {
          notificationController.success({ message: response.message });

          setVideoGalleryPreviews((prev) => prev.filter((_, i) => i !== index));
          setProductAgain && setProductAgain();
        } else {
          notificationController.error({ message: `Error removing file: ${response.message}` });
        }
      } catch (error: any) {
        console.error('Failed to delete file:', error);
        notificationController.error({ message: error.message });
      }
    } else if (isFromProduct && !id) {
      console.log('Id was not found');
    } else {
      const formDataIndex = index - (product?.fileGalleries?.length ?? 0);
      const updatedVideos = videoGallery.filter((_, i) => i !== formDataIndex);
      setVideoGallery(updatedVideos);
      onVideoChange(updatedVideos);
    }
  };

  const handleAddFile = () => {
    const updatedFiles = [...fileGallery, { title: '', file: null }];
    setFileGallery(updatedFiles);
    onFileChange(updatedFiles);
  };

  const handleFileGalleryChange = (index: number, key: 'title' | 'file', value: string | File | null) => {
    const formDataIndex = index - (product?.fileGalleries?.length ?? 0);

    const updatedFiles = fileGallery.map((item, i) => (i === formDataIndex ? { ...item, [key]: value } : item));
    setFileGallery(updatedFiles);
    onFileChange(updatedFiles);
  };

  const handleClearFile = async (index: number, id?: number) => {
    const isFromProduct = product?.fileGalleries && product.fileGalleries[index];

    if (isFromProduct && id) {
      try {
        const response = await RemoveFile(product.id, id);
        if (response.success) {
          notificationController.success({ message: response.message });

          setFileGalleryPreviews((prev) => prev.filter((_, i) => i !== index));

          setProductAgain && setProductAgain();
        } else {
          notificationController.error({ message: `Error removing file: ${response.message}` });
        }
      } catch (error: any) {
        console.error('Failed to delete file:', error);
        notificationController.error({ message: error.message });
      }
    } else if (isFromProduct && !id) {
      console.log('Id was not found');
    } else {
      const formDataIndex = index - (product?.fileGalleries?.length ?? 0);
      const updatedFiles = fileGallery.filter((_, i) => i !== formDataIndex);
      setFileGallery(updatedFiles);
      onFileChange(updatedFiles);
    }
  };

  const handleSubmitDeleteRef = useRef<(() => void) | null>(null);

  const handleSetDestroyModal = (index: number, field: string, id?: number) => {
    setDestroyModal(true);

    if (field === 'thumbnail' || field === 'imageGallery') {
      // Define the function to call `handleClearImage`, but do not execute it yet
      handleSubmitDeleteRef.current = () => handleClearImage(index, field as 'thumbnail' | 'imageGallery', id);
    } else if(field === 'fileGallery') {
      handleSubmitDeleteRef.current = () => handleClearFile(index, id);
    } else if(field === 'videoGallery') {
      handleSubmitDeleteRef.current = () => handleClearVideo(index, id);
    }
  };

  const handleSubmitDelete = () => {
    // Call the function stored in the ref, if it exists
    if (handleSubmitDeleteRef.current) {
      handleSubmitDeleteRef.current();
    }
    setDestroyModal(false); // Close the modal after the action
  };

  return (
    <>
      <BaseModal
        title={t('attributes.delete')}
        visible={destroyModal}
        centered
        size="large"
        onOk={handleSubmitDelete}
        onCancel={() => setDestroyModal(false)}
        cancelText={t('common.close')}
        okText={t('attributes.delete')}
      >
        <p>{t('attributes.delete-item-sure')}</p>
      </BaseModal>
      {/* Thumbnail */}
      <S.FormItem>
        <label>{t('products.thumbnail')}</label>
        <div>
          {thumbnailPreview && (
            <div className="img-show">
              <img src={thumbnailPreview} alt="Thumbnail preview" />
              {formData.thumbnail && <button onClick={() => handleClearImage(0, 'thumbnail')}>&times;</button>}
            </div>
          )}
        </div>
        <S.FileInput type="file" ref={thumbnailRef} onChange={(e) => handleFileChange(e, 'thumbnail')} />
      </S.FormItem>

      {/* Image Gallery */}
      <S.FormItem>
        <label>{t('products.imageGalleries')}</label>
        <div>
          {imageGalleryPreviews.map((src, index) => (
            <div className="img-show" key={index}>
              <img src={src.image} alt={`Gallery preview ${index + 1}`} />
              <button onClick={() => handleSetDestroyModal(index, 'imageGallery', src.id)}>&times;</button>
            </div>
          ))}
        </div>
        <S.FileInput type="file" ref={imageGalleryRef} multiple onChange={(e) => handleFileChange(e, 'imageGallery')} />
      </S.FormItem>

      {/* File Gallery */}
      <S.FormItem>
        <label>{t('products.fileGalleries')}</label>
        <div className="files-wrapper">
          {fileGalleryPreviews.map((file, index) => (
            <div className="file" key={index}>
              <div className="img-show">
                <a href={file.src} download>
                  <div className="stroke">
                    <FileIcon />
                  </div>
                </a>
              </div>
              <S.FormItem>
                <S.FormInput
                  type="text"
                  placeholder={t('products.fileTitle')}
                  disabled={file.notUploadable}
                  value={file.title}
                  onChange={(e) => handleFileGalleryChange(index, 'title', e.target.value)}
                />
              </S.FormItem>
              <S.FormItem>
                <S.FileInput
                  type="file"
                  ref={fileGalleryRef}
                  disabled={file.notUploadable}
                  onChange={(e) => handleFileGalleryChange(index, 'file', e.target.files?.[0] || null)}
                />
              </S.FormItem>
              <BaseSpace style={{ marginTop: '20px', marginBottom: '20px' }}>
                <BaseButton type="ghost" danger onClick={() => handleSetDestroyModal(index, 'fileGallery', file.id)}>
                  {t('common.remove')}
                </BaseButton>
              </BaseSpace>
            </div>
          ))}
        </div>
        <BaseSpace style={{ marginTop: '20px' }}>
          <BaseButton type="ghost" onClick={handleAddFile}>
            {t('common.add')}
          </BaseButton>
        </BaseSpace>
      </S.FormItem>

      {/* Video Gallery */}
      <S.FormItem>
        <label>{t('products.videoGallery')}</label>
        <div className="files-wrapper">
          {videoGalleryPreviews.map((video, index) => (
            <div key={index} className="file">
              <video src={video.src} controls style={{ width: '200px' }} />
              <S.FormItem>
                <S.FormInput
                  type="text"
                  placeholder={t('products.videoTitle')}
                  disabled={video.notUploadable}
                  value={video.title}
                  onChange={(e) => handleVideoChange(index, 'title', e.target.value)}
                />
              </S.FormItem>
              <S.FormItem>
                <S.FileInput
                  type="file"
                  disabled={video.notUploadable}
                  onChange={(e) => handleVideoChange(index, 'file', e.target.files?.[0] || null)}
                />
              </S.FormItem>
              <BaseSpace style={{ marginTop: '20px', marginBottom: '20px' }}>
                <BaseButton type="ghost" danger onClick={() => handleSetDestroyModal(index, 'videoGallery', video.id)}>
                  {t('common.remove')}
                </BaseButton>
              </BaseSpace>
            </div>
          ))}
        </div>
        <BaseSpace style={{ marginTop: '20px' }}>
          <BaseButton type="ghost" onClick={handleAddVideo}>
            {t('common.add')}
          </BaseButton>
        </BaseSpace>
      </S.FormItem>
    </>
  );
};

export default MediaForm;
