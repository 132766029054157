import styled from "styled-components";
import { Card as CommonCard } from '@app/components/common/Card/Card';
import { Button, Table as AntdTable } from 'antd';
import { FONT_SIZE, FONT_WEIGHT, media } from "@app/styles/themes/constants";
import { BaseForm } from "@app/components/common/forms/BaseForm/BaseForm";
import { Input as CommonInput } from '@app/components/common/inputs/Input/Input';


export const Card = styled(CommonCard)`
  margin-bottom: 1.25rem;
  .ant-card-head-title {
    font-size: 1rem;
  }
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    align-items: center;
  }
  .ant-card-body:before {
    display: none;
  }
  .ant-card-body:after {
    display: none;
  }
  &.ant-card-bordered {
    border: 1px solid var(--border-color);
  }
`;

export const Form = styled(BaseForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.25rem;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: ${FONT_SIZE.md};
    font-weight: ${FONT_WEIGHT.semibold};
    color: var(--primary-color);
  }

  input,
  textarea,
  select {
    font-size: ${FONT_SIZE.md};
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    background: transparent;
  }

  input[type='file'] {
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  select {
    option {
      padding: 0.5rem;
    }
  }

  ${media.md} {
    width: 100%;
  }
`;

export const FormItem = styled(BaseForm.Item)`
  width: 100%;
  min-width: 200px;
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.md};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  textarea,
  input,
  .ant-select-selector {
    background: transparent !important;
  }

  .quill {
    .ql-toolbar {
      border-radius: .25rem .25rem 0 0;
    }
    
    .ql-container {
      border-radius: 0 0 .25rem .25rem;
      min-height: 250px;
    }
  }

  .img-show {
    display: inline-block;
    margin: 10px 10px 10px 0;
    position: relative;

    img {
      width: 200px;
      height: 200px;
    }

    button {
      font-size: ${FONT_SIZE.xxxxl};
      line-height: ${FONT_SIZE.xxxxl};
      width: 2.5rem;
      height: 2.5rem;
      background: transparent;
      border: 1px solid red;
      border-radius: 5px;
      color: red;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    div {
      &.stroke {
        svg {
          stroke: var(--text-main-color);
        }
      }

      &.fill {
        svg {
          fill: var(--text-main-color);
        }
      }
    }
  }

  .price {
    margin-left: 20px;
  }

  .files-wrapper {
    display: flex;
    gap: 5%;
    flex-wrap: wrap;

    .file {
      width: 100%;
    }

    @media only screen and ${media.md} {
      gap: 5%;

      .file {
        width: 47.5%;
      }
    }
    
    @media only screen and ${media.lg} {
      gap: 3.33%;

      .file {
        width: 30%;
      }
    }
  }
`;

export const FormInput = styled(CommonInput)`
  width: 100%;
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }

  ::file-selector-button {
    background: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    outline: none;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      color: var(--secondary-color);

      border-color: var(--secondary-color);
    }
  }
`;

export const TablesWrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Table = styled(AntdTable)`
  width: 100%;
  & thead .ant-table-cell {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;

    & .anticon {
      color: var(--primary-color);
    }
  }

  & tbody .ant-table-cell {
    color: var(--text-main-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  & tbody .ant-table-row-expand-icon {
    min-height: 1.25rem;
    min-width: 1.25rem;
    border-radius: 0.1875rem;
    margin-top: 0;
  }

  // Override default antd selector
  &
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: var(--primary-color);
  }

  & .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    min-width: 2.0625rem;
    height: 2.0625rem;
    line-height: 2.0625rem;
    border-radius: 0;
    font-size: ${FONT_SIZE.xs};
  }

  & .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0;
  }

  & .ant-checkbox-inner {
    border-radius: 0.1875rem;
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid var(--primary-color);
  }

  & .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 0.75rem;
  }

  .ant-table-column-sort {
    background-color: transparent;
  }

  .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: var(--disabled-color);
  }

  .ant-pagination-disabled {
    .ant-pagination-item-link,
    .ant-pagination-item a {
      color: var(--disabled-color);
    }
  }

  .ant-pagination.ant-pagination-disabled {
    .ant-pagination-item-link,
    .ant-pagination-item a {
      color: var(--disabled-color);
    }
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1.5rem;
`;

export const StyledSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;