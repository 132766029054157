export interface VariationRaw {
  name: string;
  id?: number | undefined;
  items?: VariationRaw[];
}

export interface VariationData {
  index: number;
  name: string;
  actions: JSX.Element;
}

export const VariationTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('variations.name'),
    dataIndex: 'name',
    showSorterTooltip: true,
  },
  {
    title: t('variations.type'),
    dataIndex: 'type',
    showSorterTooltip: true,
  },
  {
    title: t('variations.actions'),
    dataIndex: 'actions',
    width: '15%',
  },
];
