import React, { useState } from 'react';
import { CreatePage } from '@app/api/Page.api';
import { notificationController } from '@app/controllers/notificationController';
import { PageForm } from './PageForm';
import { PageModel } from '@app/domain/Page.model';
import * as S from './styles.style';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export const PageCreate: React.FC = () => {
  const [formData, setFormData] = useState<PageModel>({
    id: 0,
    uuid: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    title: '',
    content: '',
    slug: '',
  });
  const navigator = useNavigate();

  const createPage = async (formData: PageModel) => {
    try {
      const response = await CreatePage(formData);
      if (response.success) {
        notificationController.success({ message: 'Page created successfully!' });
        setFormData({
          id: 0,
          uuid: '',
          createdAt: new Date(),
          updatedAt: new Date(),
          title: '',
          content: '',
          slug: '',
        });
        navigator('/pages');
      } else {
        notificationController.error({ message: `Error creating page: ${response.message}` });
      }
    } catch (error) {
      console.error('Error creating page:', error);
      notificationController.error({ message: 'Failed to create page.' });
    }
  };

  return (
    <S.PageWrapper>
      <PageTitle>Pages</PageTitle>
      <PageForm
        formData={formData}
        setFormData={setFormData}
        updateProduct={createPage} // Reusing updateProduct prop
      />
    </S.PageWrapper>
  );
};
