import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './AttributePage.style';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { notificationController } from '@app/controllers/notificationController';
import { DeleteAttribute, fetchAttributeByUuid, UpdateAttribute } from '@app/api/Attribute.api';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { CategoryModel } from '@app/domain/CategoryModel';
import { Categories } from '@app/api/Category.api';
import { categoryOption } from './NewAttributePage';
import { validate } from 'uuid';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const UpdateAttributePage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    title: '',
    category: { label: '', value: 0 },
    inputs: [{ title: '', id: 0, items: [{ title: '', id: 0 }] }],
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [destroyModal, setDestroyModal] = useState(false);
  const [id, setId] = useState<number | undefined>(0);
  const [categories, setCategories] = useState<CategoryModel[]>([]);

  const handleOpenDestroyModal = (id: number | undefined) => {
    setDestroyModal(true);
    setId(id);
  };

  const fetchCategories = useCallback(() => {
    Categories({ type: 'product', page: 1 })
      .then((response) => {
        if (response.success) {
          setCategories(response.data.categories);
        } else {
          notificationController.error({ message: response.message });
        }
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    const uuid = window.location.pathname.split('/').pop();
    fetchAttributeByUuid(uuid!).then((data) => {
      setFormData({
        title: data.data.title,
        category: { label: data.data.category.title, value: data.data.category.id },
        inputs: data.data.items.map((item: any) => ({
          title: item.title,
          id: item.id,
          items: item.items.map((subItem: any) => ({
            title: subItem.title,
            id: subItem.id,
          })),
        })),
      });
    });
  }, []);

  const handleSubmitUpdate = (values: any) => {
    setLoading(true);
    UpdateAttribute(location.state.id, formData)
      .then((result) => {
        if (result.success) {
          notificationController.success({ message: result.message });
          navigate('/products/attributes');
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  const handleAddRow = (index: number) => {
    const rows = [...formData.inputs];
    rows[index].items.push({ title: '', id: 0 });
    setFormData({ ...formData, inputs: rows });
  };

  const handleAddRow2 = () => {
    setFormData({
      ...formData,
      inputs: [...formData.inputs, { title: '', id: 0, items: [{ title: '', id: 0 }] }],
    });
  };

  const removeRowItem = (index: number) => {
    const rows = [...formData.inputs];
    if (rows[index].id === 0) {
      rows.splice(index, 1);
      setFormData({ ...formData, inputs: rows });
    } else {
      handleOpenDestroyModal(rows[index].id);
    }
  };

  const removeRowSubItem = (index: number, subIndex: number) => {
    const rows = [...formData.inputs];
    if (rows[index].items[subIndex].id === 0) {
      rows[index].items.splice(subIndex, 1);
      setFormData({ ...formData, inputs: rows });
    } else {
      handleOpenDestroyModal(rows[index].items[subIndex].id);
    }
  };

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const rows = [...formData.inputs];
    rows[index].title = value;
    setFormData({ ...formData, inputs: rows });
  };

  const handleChangeSub = (index: number, subIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const rows = [...formData.inputs];
    rows[index].items[subIndex].title = value;
    setFormData({ ...formData, inputs: rows });
  };

  const handleSubmitDelete = () => {
    setLoading(true);
    DeleteAttribute(id, location.state.id)
      .then((result) => {
        if (result.success) {
          notificationController.success({ message: result.message });
          setFormData({
            ...formData,
            inputs: result.data.items.map((item: any) => ({
              title: item.title,
              id: item.id,
              items: item.items.map((subItem: any) => ({
                title: subItem.title,
                id: subItem.id,
              })),
            })),
          });
          setDestroyModal(false);
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  return (
    <>
      <PageTitle>Attributes</PageTitle>
      {formData.title && (
        <S.Card title={t('attributes.update-attribute')} padding="1.25rem 1.25rem 0">
          <BaseForm
            style={{ width: '70%', margin: '0 auto' }}
            layout="vertical"
            onFinish={handleSubmitUpdate}
            initialValues={formData}
          >
            <S.FormItem
              name="category"
              label={t('products.category')}
              required={true}
              rules={[
                {
                  validator: () =>
                    formData.category.value !== 0
                      ? Promise.resolve()
                      : Promise.reject(new Error(t('common.requiredField'))),
                },
              ]}
            >
              <S.Select
                placeholder={t('products.selectCategory')}
                options={[
                  { label: 'Choose', value: 0 }, // Default placeholder option
                  ...categories.map((category) => ({
                    label: category.title,
                    value: category.id,
                  })),
                ]}
                onChange={(value, option) =>
                  setFormData({
                    ...formData,
                    category: { label: (option as categoryOption).label, value: value as number },
                  })
                }
              />
            </S.FormItem>
            <S.FormItem
              name="title"
              label={t('attributes.title')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.FormInput
                placeholder={t('categories.title')}
                value={formData.title}
                onChange={(event) => setFormData({ ...formData, title: event.target.value })}
              />
            </S.FormItem>
            {formData.inputs.map((input, index) => (
              <BaseCol key={index} style={{ marginLeft: '50px' }}>
                <S.FormItem
                  name={`inputs[${index}].title`}
                  label={t('attributes.title')}
                  required={true} // Ensures the red asterisk appears
                  rules={[
                    {
                      validator: () =>
                        input.title ? Promise.resolve() : Promise.reject(new Error(t('common.requiredField'))),
                    },
                  ]}
                >
                  <S.InputsWrapper>
                    <S.FormInput
                      value={input.title}
                      placeholder={t('categories.title')}
                      onChange={(event: any) => handleChange(index, event)}
                    />
                    <S.SubmitButton style={{ width: '50%' }} type="primary" onClick={() => handleAddRow(index)}>
                      {t('attributes.add')}
                    </S.SubmitButton>
                    <S.SubmitButton style={{ width: '50%' }} danger type="primary" onClick={() => removeRowItem(index)}>
                      {t('attributes.remove')}
                    </S.SubmitButton>
                  </S.InputsWrapper>
                </S.FormItem>
                {input.items.map((_input, _index) => (
                  <BaseCol key={_index} style={{ marginLeft: '100px' }}>
                    <S.FormItem
                      name={`inputs[${index}].items[${_index}].title`}
                      label={t('attributes.title')}
                      required={true} // Ensures the red asterisk appears
                      rules={[
                        {
                          validator: () =>
                            _input.title ? Promise.resolve() : Promise.reject(new Error(t('common.requiredField'))),
                        },
                      ]}
                    >
                      <S.InputsWrapper>
                        <S.FormInput
                          value={_input.title}
                          placeholder={t('categories.title')}
                          onChange={(event: any) => handleChangeSub(index, _index, event)}
                        />
                        <S.SubmitButton
                          style={{ width: '50%' }}
                          danger
                          type="primary"
                          onClick={() => removeRowSubItem(index, _index)}
                        >
                          {t('attributes.remove')}
                        </S.SubmitButton>
                      </S.InputsWrapper>
                    </S.FormItem>
                  </BaseCol>
                ))}
              </BaseCol>
            ))}
            <S.InputsWrapper>
              <S.SubmitButton style={{ width: '50%' }} type="primary" htmlType="button" onClick={handleAddRow2}>
                {t('attributes.add')}
              </S.SubmitButton>
              <S.SubmitButton type="ghost" htmlType="submit" loading={loading}>
                {t('common.submit')}
              </S.SubmitButton>
            </S.InputsWrapper>
          </BaseForm>
        </S.Card>
      )}

      <BaseModal
        title={t('attributes.delete')}
        visible={destroyModal}
        centered
        size="large"
        onOk={handleSubmitDelete}
        onCancel={() => setDestroyModal(false)}
        cancelText={t('common.close')}
        okText={t('attributes.delete')}
      >
        <p>{t('attributes.delete-item-sure')}</p>
      </BaseModal>
    </>
  );
};
export default UpdateAttributePage;
