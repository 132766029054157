import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import authSlice from '@app/store/slices/authSlice';
import userSlice from '@app/store/slices/userSlice';
export default {
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  auth: authSlice,
  user: userSlice,
};
