import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './AttributePage.style';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { AttributeRow } from '@app/interfaces/attribute.interface';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { StoreAttribute } from '@app/api/Attribute.api';
import { Categories } from '@app/api/Category.api';
import { CategoryModel } from '@app/domain/CategoryModel';
import { Form } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

export const initValues = {
  title: '',
  items: [{ title: '' }],
  category: { label: '', value: 0 },
};

export interface categoryOption {
  label: string;
  value: number;
}

const NewAttributePage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [inputs, setInputs] = useState<AttributeRow[]>([{ title: '', items: [{ title: '' }] }]);
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [category, setCategory] = useState<categoryOption | null>({label: 'Choose', value: 0});
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const fetchCategories = useCallback(() => {
    Categories({ type: 'product', page: 1 })
      .then((response) => {
        if (response.success) {
          setCategories(response.data.categories);
        } else {
          notificationController.error({ message: response.message });
        }
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleSubmitCreate = (values: any) => {
    setLoading(true);

    StoreAttribute({ title: values.title, inputs, category: category as categoryOption })
      .then((result) => {
        if (result.success) {
          notificationController.success({ message: result.message });
          navigate('/products/attributes');
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  const handleAddRow = (index: number) => {
    const rows = [...inputs];
    rows[index].items?.push({ title: '' });
    setInputs(rows);
  };

  const handleAddRow2 = () => {
    setInputs([...inputs, { title: '', items: [{ title: '' }] }]);
  };

  const removeRowItem = (index: number) => {
    const rows = [...inputs];
    rows.splice(index, 1);
    setInputs(rows);
  };

  const removeRowSubItem = (index: number, subIndex: number) => {
    const rows = [...inputs];
    rows[index].items?.splice(subIndex, 1);
    setInputs(rows);
  };

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const list = [...inputs];

    list[index].title = value;
    setInputs(list);
  };

  const handleChangeSub = (index: number, subIndex: string | number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const list = [...inputs];

    list[index].items![subIndex].title = value;
    setInputs(list);
  };

  return (
    <>
      <S.Card title={t('attributes.new-attribute')} padding="1.25rem 1.25rem 0">
        <PageTitle>Attributes</PageTitle>
        <BaseForm
          style={{ width: '70%', margin: '0 auto 0 auto' }}
          layout="vertical"
          onFinish={handleSubmitCreate}
          requiredMark={true}
          initialValues={initValues}
          form={form}
        >
          {/* Form Item for Category */}
          <S.FormItem
            name="cat"
            label={t('products.category')}
            required={true}
            rules={[
              {
                validator: () =>
                  category?.value !== 0
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('common.requiredField'))),
              },
            ]}
          >
            <S.Select
              placeholder={t('products.selectCategory')}
              defaultValue={0}
              options={[
                { label: 'Choose', value: 0 }, // Default placeholder option
                ...categories.map((category) => ({
                  label: category.title,
                  value: category.id,
                })),
              ]}
              onChange={(value, option) =>
                setCategory({ label: (option as categoryOption).label, value: value as number })
              }
            />
          </S.FormItem>

          {/* Other Form Items */}
          <S.FormItem
            name="title"
            label={t('attributes.title')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <S.FormInput
              placeholder={t('categories.title')}
              onChange={(e) => (form as any).setFieldsValue({ title: e.target.value })}
            />
          </S.FormItem>

          {/* Inputs for Dynamic Rows */}
          {inputs.map((input, index) => (
            <BaseCol key={index} style={{ marginLeft: '50px' }}>
              <S.FormItem
                name={`title${index}`}
                label={t('attributes.title')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <S.InputsWrapper>
                  <S.FormInput placeholder={t('categories.title')} onChange={(event) => handleChange(index, event)} />
                  <S.SubmitButton
                    style={{ width: '50%' }}
                    type="primary"
                    htmlType="button"
                    onClick={() => handleAddRow(index)}
                  >
                    {t('attributes.add')}
                  </S.SubmitButton>
                  {index >= 1 && (
                    <S.SubmitButton
                      style={{ width: '50%' }}
                      danger={true}
                      type="primary"
                      htmlType="button"
                      onClick={() => removeRowItem(index)}
                    >
                      {t('attributes.remove')}
                    </S.SubmitButton>
                  )}
                </S.InputsWrapper>
              </S.FormItem>
              {input.items?.map((_input, _index) => (
                <BaseCol key={`${index}-${_index}`} style={{ marginLeft: '100px' }}>
                  <S.FormItem
                    name={`title${index}${_index}`}
                    label={t('attributes.title')}
                    rules={[{ required: true, message: t('common.requiredField') }]}
                  >
                    <S.InputsWrapper>
                      <div style={{ width: '100%' }}>
                        <S.FormInput
                          placeholder={t('categories.title')}
                          onChange={(event) => handleChangeSub(index, _index, event)}
                        />
                      </div>
                      {_index >= 1 && (
                        <S.SubmitButton
                          style={{ width: '50%' }}
                          type="primary"
                          danger={true}
                          htmlType="button"
                          onClick={() => removeRowSubItem(index, _index)}
                        >
                          {t('attributes.remove')}
                        </S.SubmitButton>
                      )}
                    </S.InputsWrapper>
                  </S.FormItem>
                </BaseCol>
              ))}
            </BaseCol>
          ))}

          {/* Add Row and Submit Button */}
          <S.InputsWrapper>
            <S.SubmitButton style={{ width: '50%' }} type="primary" htmlType="button" onClick={handleAddRow2}>
              {t('attributes.add')}
            </S.SubmitButton>
            <S.SubmitButton type="ghost" htmlType="submit" loading={loading}>
              {t('common.submit')}
            </S.SubmitButton>
          </S.InputsWrapper>
        </BaseForm>
      </S.Card>
    </>
  );
};
export default NewAttributePage;
