import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './InquiryPage.style';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { SearchUser } from '@app/api/User.api';
import { notificationController } from '@app/controllers/notificationController';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { UserModal } from '@app/domain/User.model';
import {
  UserSearchData,
  UserSearchData2,
  UserSearchTableColumns,
  UserSearchTableColumns2,
} from '@app/interfaces/user.interface';
import { EditableCell } from './EditableCell';
import { InquiryProductData, InquiryProductTableColumns } from '@app/interfaces/inquiry.interface';
import { ProductSearchRequest, SearchProduct } from '@app/api/Product.api';
import { InquiryCreateRequest, StoreInquiry } from '@app/api/Inquiry.api';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ProductModel } from '@app/domain/Product.model';

const ShowInquiryPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserSearchData2[]>([]);
  const [products, setProducts] = useState<InquiryProductData[]>([]);
  const [initValues, setInitValues] = useState({
    title: '',
    email: '',
    user: '',
    totalPrice: '',
    shippingCost: '',
    discount: '',
    vat: '',
    status: 'final',
    description: '',
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setUsers([location.state.user]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const productList: InquiryProductData[] = location.state?.products?.map((product: ProductModel) => ({
      id: product.price.product.id,
      index: product.price.product.id,
      title: product.price.product.title,
      sku: product.price.product.sku,
      quantity: product.quantity,
      price: product.price.price,
      discount: product.price.discount,
      description: product.price.product.shortDescription,
      actions: <></>,
    }));
    setProducts(productList);
    location?.state?.email &&
      setInitValues({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title: location.state.title,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        email: location.state.email,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user: location.state.user,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        totalPrice: location.state.totalPrice,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        shippingCost: location.state.shippingCost,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        discount: location.state.discount,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        vat: location.state.vat,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        status: location.state.status,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        description: location.state.description,
      });
  }, [location.state]);

  return (
    <>
      {initValues?.email && (
        <>
          <PageTitle>Inquiries</PageTitle>
          <S.Card title={t('inquiries.show-inquiry')} padding="1.25rem 1.25rem 0">
            <S.TablesWrapper>
              <BaseForm
                style={{ width: '100%', margin: '0 auto 0 auto' }}
                layout="vertical"
                onFinish={() => {}}
                requiredMark="optional"
                initialValues={initValues}
              >
                <S.InputsWrapper>
                  <S.FormItem
                    style={{ width: '100%' }}
                    name="email"
                    label={t('inquiries.email')}
                    rules={[{ required: false, message: t('common.requiredField') }]}
                  >
                    <S.FormInput
                      placeholder={t('inquiries.email')}
                      value={initValues.email}
                      onChange={(target) => (initValues.email = target.target.value)}
                    />
                  </S.FormItem>
                </S.InputsWrapper>
                <S.Table
                  scroll={{ x: 800 }}
                  columns={UserSearchTableColumns2(t)}
                  dataSource={users}
                  loading={loading}
                />
                <S.InputsWrapper>
                  <S.FormItem
                    style={{ width: '100%' }}
                    name="discount"
                    label={t('inquiries.discount')}
                    rules={[{ required: false, message: t('common.requiredField') }]}
                  >
                    <S.FormInput
                      placeholder={t('inquiries.discount')}
                      onChange={(target) => (initValues.discount = target.target.value)}
                    />
                  </S.FormItem>
                  <S.FormItem
                    style={{ width: '100%' }}
                    name="shippingCost"
                    label={t('inquiries.shippingCost')}
                    rules={[{ required: false, message: t('common.requiredField') }]}
                  >
                    <S.FormInput
                      placeholder={t('inquiries.shippingCost')}
                      onChange={(target) => (initValues.shippingCost = target.target.value)}
                    />
                  </S.FormItem>
                </S.InputsWrapper>
                <S.InputsWrapper>
                  <S.FormItem
                    style={{ width: '100%' }}
                    name="vat"
                    label={t('inquiries.vat')}
                    rules={[{ required: false, message: t('common.requiredField') }]}
                  >
                    <S.FormInput
                      placeholder={t('inquiries.vat')}
                      onChange={(target) => (initValues.vat = target.target.value)}
                    />
                  </S.FormItem>
                  <S.FormItem
                    style={{ width: '100%' }}
                    name="status"
                    label={t('inquiries.status')}
                    rules={[{ required: false, message: t('common.requiredField') }]}
                  >
                    <S.FormInput
                      placeholder={t('inquiries.status')}
                      onChange={(target) => (initValues.status = target.target.value)}
                    />
                  </S.FormItem>
                </S.InputsWrapper>
                <S.FormItem
                  style={{ width: '100%' }}
                  name="description"
                  label={t('inquiries.description')}
                  rules={[{ required: false, message: t('common.requiredField') }]}
                >
                  <S.FormInputLong
                    rows={10}
                    placeholder={t('inquiries.description')}
                    onChange={(target) => (initValues.description = target.target.value)}
                  />
                </S.FormItem>

                <S.Table
                  scroll={{ x: 800 }}
                  columns={InquiryProductTableColumns(t)}
                  dataSource={products}
                  loading={loading}
                />
              </BaseForm>
            </S.TablesWrapper>
          </S.Card>
        </>
      )}
    </>
  );
};

export default ShowInquiryPage;
